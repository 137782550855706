import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import logoCredicuotas from '../../images/logoCredicuotas.png';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { List, ListItemText, Link } from '@mui/material';
import logoCredicuotas from '../../images/logoCredicuotas.png';
import { route } from '../navigation/routes';
import {
  PRIVACY, TERMS, AUTOGESTION, COMMERCE, getEnv,
} from '../../api/env';
import i18n from './i18n';
import '../../assests/css/footerStyle.css';
import logoFacebook from '../../assests/images/footer/logoFacebook.svg';
import logoInstagram from '../../assests/images/footer/logoInstagram.svg';
import logoLinkedin from '../../assests/images/footer/logoLinkedin.svg';
import logoYoutube from '../../assests/images/footer/logoYoutube.svg';

const Footer = () => {
  const PRIVACY_LINK = getEnv(PRIVACY);
  const TERMS_LINK = getEnv(TERMS);
  const COMMERCE_LINK = getEnv(COMMERCE);
  const CLIENT_LINK = getEnv(AUTOGESTION);

  const [hide, setHide] = useState(false);
  const location = useLocation();
  const pathToExcludeFooter = [
    route.whatsapp.toLowerCase(),
    route.uber.toLowerCase(),
    route.branch.toLowerCase(),
    route.recharge.toLowerCase(),
    route.services.toLowerCase(),
    route.cellphoneRecharge.toLowerCase(),
  ];

  const init = () => {
    const hideFooter = pathToExcludeFooter.includes(location.pathname.toLowerCase());
    setHide(hideFooter);
  };

  useEffect(() => init(), []);

  return (
    <div>
      {!hide && (
        <Box className="boxFooter">
          <Grid container pt={3}>
            <Grid item xs={12} lg={3} className="gridLogoCredicuotas">
              <Link href={route.home} color="inherit" underline="none">
                <img
                  src={logoCredicuotas}
                  alt={i18n.credicuotas}
                  className="imgLogo"
                />
              </Link>
              <Grid container className="gridSocialNetwork">
                <Grid item lg={2} />
                <Grid item lg={2} mt={0.5}>
                  <a
                    href="https://www.facebook.com/credicuotasarg"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <img
                      src={logoFacebook}
                      alt={i18n.footerFacebook}
                      className="imgLogosSocialNetwork"
                    />
                  </a>
                </Grid>
                <Grid item lg={2} mt={0.5}>
                  <a
                    href="https://www.instagram.com/credicuotas.ar/"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <img
                      src={logoInstagram}
                      alt={i18n.footerInstagram}
                      className="imgLogosSocialNetwork"
                    />
                  </a>
                </Grid>
                <Grid item lg={2} mt={0.5}>
                  <a
                    href="https://www.linkedin.com/company/credicuotas/mycompany/"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <img
                      src={logoLinkedin}
                      alt={i18n.footerLinkedin}
                      className="imgLogosSocialNetwork"
                    />
                  </a>
                </Grid>
                <Grid item lg={2} mt={0.5}>
                  <a
                    href="https://www.youtube.com/channel/UCIsQZSpW2M4DlGCSM6pBsWQ"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <img
                      src={logoYoutube}
                      alt={i18n.footerYoutube}
                      className="imgLogosSocialNetwork"
                    />
                  </a>
                </Grid>
                <Grid item lg={2} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ textAlign: 'left' }} className="gridMapSite">
              <List>
                <ListItemText>
                  <Typography>
                    <Link href={route.us} color="inherit" underline="none">
                      {i18n.footerWe}
                    </Link>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <Link href="/" color="inherit" underline="none">
                      {i18n.footerPersonalLoans}
                    </Link>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText className="ocultar">
                  <Typography>
                    <Link href={route.buyNowPayLater} color="inherit" underline="none">
                      {i18n.footerBuyOnline}
                    </Link>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <Link href={route.physicalCommerce} color="inherit" underline="none">
                      {i18n.footerBuyCommerce}
                    </Link>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <Link href={route.card} color="inherit" underline="none">
                      {i18n.footerCard}
                    </Link>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <Link href={route.payServices} color="inherit" underline="none">
                      {i18n.footerPayServices}
                    </Link>
                  </Typography>
                </ListItemText>
                {/* <ListItemText>
                  <Typography>
                    <Link href="/" color="inherit" underline="none">
                      {i18n.footerSucursal}
                    </Link>
                  </Typography>
                  </ListItemText> */}
              </List>
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ textAlign: 'left' }} className="gridMapSite">
              <List>
                <ListItemText>
                  <Typography>
                    <Link href={COMMERCE_LINK} color="inherit" target="_blank" underline="none">
                      {i18n.footerCommerce}
                    </Link>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <Link href={CLIENT_LINK} color="inherit" target="_blank" underline="none">
                      {i18n.footerClient}
                    </Link>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <Link href={route.blog} color="inherit" underline="none">
                      {i18n.footerBlog}
                    </Link>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <Link href={route.help} color="inherit" underline="none">
                      {i18n.footerHelp}
                    </Link>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <Link href={route.investors} color="inherit" underline="none">
                      {i18n.footerInvestors}
                    </Link>
                  </Typography>
                </ListItemText>
                {/* <ListItemText>
                  <Typography>
                    <Link href={route.jobs} color="inherit" underline="none" rel="nofollow">
                      {i18n.footerWorkWithUs}
                    </Link>
                  </Typography>
                </ListItemText>
                <ListItemText>
                  <Typography>
                    <Link href={route.investors} color="inherit" underline="none">
                      {i18n.footerInvestors}
                    </Link>
                  </Typography>
                </ListItemText> */}
                <Box mt={4} />
                <ListItemText>
                  <List>
                    <ListItemText>
                      <Typography className="contactTitle">
                        {i18n.footerContactTitle}
                      </Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography className="contactTitle">
                        {i18n.footerContactWhatsappTitle}
                      </Typography>
                      <Typography className="contactData">
                        {i18n.footerContactWhatsappData}
                      </Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography className="contactTitle">
                        {i18n.footerContactMailTitle}
                      </Typography>
                      <Typography className="contactData">
                        {i18n.footerContactMailData}
                      </Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography className="contactTitle">
                        {i18n.footerContactPhoneTitle}
                      </Typography>
                      <Typography className="contactData">
                        {i18n.footerContactPhoneData}
                      </Typography>
                    </ListItemText>
                  </List>
                </ListItemText>
              </List>
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ textAlign: 'left', alignItems: 'center' }} className="gridMapSite">
              <List>
                <ListItemText>
                  <Typography>
                    <a
                      href={PRIVACY_LINK}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {i18n.footerPrivacy}
                    </a>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <Link href={route.consumerProtection} color="inherit" underline="none" rel="nofollow">
                      {i18n.footerConsumerDefense}
                    </Link>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <a
                      href={route.contracts}
                      rel="noopener noreferrer nofollow"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {i18n.footerContract}
                    </a>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <Link href={route.financeUserInformation} color="inherit" underline="none" rel="nofollow">
                      {i18n.financeUserInformation}
                    </Link>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <a
                      href={TERMS_LINK}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {i18n.footerConditions}
                    </a>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <Link href={route.cancelCredit} color="inherit" underline="none" rel="nofollow">
                      {i18n.footerRepentanceButton}
                    </Link>
                  </Typography>
                </ListItemText>
                <Box mt={1} />
                <ListItemText>
                  <Typography>
                    <Link href={route.unsubscribeInformation} color="inherit" underline="none" rel="nofollow">
                      {i18n.footerUnsuscribeButton}
                    </Link>
                  </Typography>
                </ListItemText>
              </List>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default Footer;
