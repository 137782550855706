import parse from 'html-react-parser';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { isMobileOperatingSystem } from '../utils/functionUtils';

const LandingBrandAndModels = ({
  title, description, brands, brandsMobile,
}) => (
  <>
    <Typography ml={2} mr={2} variant="h2">{title}</Typography>
    <Box mt={5} mb={5} className="landing-models">
      <img
        src={isMobileOperatingSystem() ? brandsMobile : brands}
        alt="BRAND_1"
      />
      <Box mt={2} />
      <Typography>{parse(description)}</Typography>
    </Box>
  </>
);

LandingBrandAndModels.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  brands: PropTypes.string.isRequired,
  brandsMobile: PropTypes.string.isRequired,
};

export default LandingBrandAndModels;
