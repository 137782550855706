import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import parse from 'html-react-parser';
import i18n from '../common/i18n';
import Banner from '../common/Banner';
import bannerImageBackground from '../../assests/images/home/bannerHome.svg';
import bannerImage from '../../assests/images/investors/banner_img.png';
import credicuotasBindImage from '../../assests/images/investors/credicuotas_bind.png';
import director1Image from '../../assests/images/investors/director_1.png';
import director2Image from '../../assests/images/investors/director_2.png';
import financialReportImg from '../../assests/images/investors/financial_report_img.png';
import HeaderTags from '../common/HeaderTags';
import '../../assests/css/investorsStyle.css';
import AvatarCard from '../AvatarCard';
import { INVESTOR_FINANCIAL_REPORT_URL, getEnv } from '../../api/env';
import PressCard from '../PressCard';
import HistorySlider from '../HistorySlider';

const Investors = () => {
  const goToFinancialReport = () => {
    window.open(`${getEnv(INVESTOR_FINANCIAL_REPORT_URL)}?fdesde=17/8/2019`, '_blank');
  };

  return (
    <>
      <HeaderTags title={i18n.footerInvestors} content={i18n.footerInvestors} />
      {/* banner */}
      <Box mt={10}>
        <Banner
          title={i18n.investorsBannerTitle}
          subtitle={parse(i18n.investorsBannerSubtitle)}
          backgroundImage={bannerImageBackground}
          iconImage={bannerImage}
        />
      </Box>
      {/* About credicuotas */}
      <Grid container className="investors-container">
        <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
          <Box className="spaceBetween">
            <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxHelpTypography">
              {i18n.investorsAboutCredicuotas}
            </Typography>
          </Box>
          <Box mt={3} className="spaceBetween" maxWidth={477}>
            <Typography>{i18n.investorsAboutCredicuotasParagraph1}</Typography>
          </Box>
          <Box mt={3} className="spaceBetween" maxWidth={477}>
            <Typography>{i18n.investorsAboutCredicuotasParagraph2}</Typography>
          </Box>
          <Box mt={3} className="spaceBetween" maxWidth={477}>
            <Typography>{i18n.investorsAboutCredicuotasParagraph3}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="spaceBetween">
            <img src={credicuotasBindImage} alt="Credicuotas_Bind_Logo" className="investors-credicuotas-bind-logo" />
          </Box>
        </Grid>
      </Grid>
      {/* Directors */}
      <Box className="flexCenter spaceBetween">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxHelpTypography">
          {i18n.investorsDirectorsTitle}
        </Typography>
      </Box>
      <Box mt={2} className="investors-container">
        <AvatarCard
          img={director1Image}
          title={i18n.investorsDirectors1Name}
          subtitle={i18n.investorsDirectors1Position}
          description={i18n.investorsDirectors1Phrase}
        />
        <Box mt={2} />
        <AvatarCard
          img={director2Image}
          title={i18n.investorsDirectors2Name}
          subtitle={i18n.investorsDirectors2Position}
          description={i18n.investorsDirectors2Phrase}
        />
      </Box>
      {/* More about us */}
      <Box className="flexCenter spaceBetween">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxHelpTypography">
          {i18n.investorsMoreAboutUs}
        </Typography>
      </Box>
      <Box mt={3} />
      <Grid container className="investors-container-extended">
        <Grid item xs={12} md={6}>
          <Box className="investors-about-data-box">
            <Typography className="investors-about-title">{i18n.investorsAboutBox1Title}</Typography>
            <Typography className="investors-about-description">{i18n.investorsAboutBox1Description}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="investors-about-data-box">
            <Typography className="investors-about-title">{i18n.investorsAboutBox2Title}</Typography>
            <Typography className="investors-about-description">{i18n.investorsAboutBox2Description}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="investors-about-data-box">
            <Typography className="investors-about-title">{i18n.investorsAboutBox3Title}</Typography>
            <Typography className="investors-about-description">{i18n.investorsAboutBox3Description}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="investors-about-data-box">
            <Typography className="investors-about-title">{i18n.investorsAboutBox4Title}</Typography>
            <Typography className="investors-about-description">{i18n.investorsAboutBox4Description}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box className="flexCenter spaceBetween">
        <Typography className="investors-contact-us">
          {i18n.investorsContactUsText}
          <a
            href="mailTo: inversores@credicuotas.com.ar"
            rel="noopener noreferrer"
            style={{ color: '#000000' }}
          >
            {i18n.investorsContactUsEmail}
          </a>
        </Typography>
      </Box>
      {/* Our history */}
      <Box className="flexCenter spaceBetween">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxHelpTypography">
          {i18n.investorsOurHistoryTitle}
        </Typography>
      </Box>
      <Box mt={3} />
      <HistorySlider />
      <Box mt={3} />
      {/* Financial report */}
      <Box className="investors-financial-report">
        <Box mt={3} sx={{ textAlign: 'left' }}>
          <Typography className="investors-financial-report-title">
            {parse(i18n.investorsFinancialReportTitle)}
          </Typography>
          <Box mt={2} />
          <Button
            onClick={goToFinancialReport}
            variant="contained"
            className="ButtonMagenta buttonCredit"
            sx={{ width: '200px' }}
          >
            {i18n.investorsFinancialReportCTA}
          </Button>
        </Box>
        <Box mt={3}>
          <img src={financialReportImg} alt="financial_report" className="investors-financial-report-img" />
        </Box>
      </Box>
      {/* Credicuotas in the press */}
      <Box className="flexCenter spaceBetween">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxHelpTypography">
          {i18n.investorsPressTitle}
        </Typography>
      </Box>
      <Box mb={10} className="investors-press-container">
        {i18n.investorsPress.map((press) => (
          <Box mt={3} key={press.title}>
            <PressCard
              title={press.title}
              description={press.description}
              footer={press.footer}
              redirectLink={press.link}
              redirectLabel={i18n.investorsPressCTALabel}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Investors;
