import PropTypes from 'prop-types';
import { Avatar, Box, Typography } from '@mui/material';
import parse from 'html-react-parser';
import '../assests/css/avatarCardStyle.css';

const AvatarCard = ({
  img, title, subtitle, description,
}) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Box className="avatar-card-box">
      <Box mt={3}>
        <Avatar src={img} className="avatar-card-img" />
      </Box>
      <Box mt={3}>
        <Box>
          <Typography className="avatar-card-title">{title}</Typography>
        </Box>
        <Box>
          <Typography className="avatar-card-subtitle">{subtitle}</Typography>
        </Box>
      </Box>
    </Box>
    <Box mt={3} maxWidth={700}>
      <Typography>{parse(description)}</Typography>
    </Box>
  </Box>
);

AvatarCard.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default AvatarCard;
