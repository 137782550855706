export const AUTOGESTION = 'AUTOGESTION';
export const COMMERCE = 'COMMERCE';
export const PRIVACY = 'PRIVACY';
export const CONTRACT_WT = 'CONTRACT_WT';
export const CONTRACT_WC = 'CONTRACT_WC';
export const CONTRACT_SUC_TUC = 'CONTRACT_SUC_TUC';
export const CONTRACT_TARJETA_PREPAGA = 'CONTRACT_TARJETA_PREPAGA';
export const TERMS = 'TERMS';
export const SELFIE_WEB = 'SELFIE_WEB';
export const BLOG = 'BLOG';
export const ORIGINACION_WT = 'ORIGINACION_WT';
export const ENVIRONMENT = 'ENVIRONMENT';
export const SEAC = 'SEAC';
export const UBER = 'UBER';
export const RECARGACELULAR = 'RECARGACELULAR';
export const PAGOSERVICIOS = 'PAGOSERVICIOS';
export const GOOGLE_ANALYTICS_ID = 'GOOGLE_ANALYTICS_ID';
export const GOOGLE_TAG_MANAGER_ID = 'GOOGLE_TAG_MANAGER_ID';
export const ZENDESK = 'ZENDESK';
export const INVESTOR_FINANCIAL_REPORT_URL = 'INVESTOR_FINANCIAL_REPORT_URL';
export const INVESTOR_PRESS_NOTE_1_URL = 'INVESTOR_PRESS_NOTE_1_URL';
export const INVESTOR_PRESS_NOTE_2_URL = 'INVESTOR_PRESS_NOTE_2_URL';
export const INVESTOR_PRESS_NOTE_3_URL = 'INVESTOR_PRESS_NOTE_3_URL';
export const SERVICE_URL = 'SERVICE_URL';
export const RECHARGE_URL = 'RECHARGE_URL';
export const PERSONAL_LOAN_URL = 'PERSONAL_LOAN_URL';

export const getEnv = (key) => window.env[key];
