import {
  Grid, Box, Button, Typography,
} from '@mui/material';
import { route } from '../navigation/routes';
import i18n from '../common/i18n';
import '../../assests/css/services.css';
import logoCredicuotas from '../../images/logoCredicuotas.png';
import bannerRecargaCelular from '../../assests/images/servicios/bannerServicios.png';
import iconContador from '../../assests/images/servicios/servicioPagar.png';
import iconDocumento from '../../assests/images/servicios/identidad.png';
import iconAnotador from '../../assests/images/servicios/servicioPago.png';
import {
  PAGOSERVICIOS, getEnv,
} from '../../api/env';
import LegalFooter from '../common/LegalFooter';
import HeaderTags from '../common/HeaderTags';

const Services = () => {
  const PAGOSERVICIOS_LINK = getEnv(PAGOSERVICIOS);
  return (
    <>
      <HeaderTags
        title={i18n.serviceName}
        content={i18n.serviceName}
        metaContent="noindex, nofollow"
      />
      <header style={{ backgroundColor: 'white' }}>
        <Grid container>
          <Grid item xs={10} md={2} className="gridLogoHeader">
            <img
              src={logoCredicuotas}
              alt={i18n.credicuotas}
              className="logoHeader"
            />
          </Grid>
        </Grid>
      </header>
      {/* Banner */}
      <Grid container className="bannerRecargaCelular" mt={11}>
        <Grid item md={6} xs={12}>
          <Box className="bannerText">
            <Typography variant="h2" fontWeight={800} color="#383838" className="serviceTitle">
              {i18n.serviceTitle}
            </Typography>
            <Box className="serviceSubtitle">
              <Typography variant="h5" fontWeight={600}>{i18n.serviceSubtitle}</Typography>
            </Box>
          </Box>
          <Box className="boxButton">
            <a
              href={PAGOSERVICIOS_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit', paddingTop: '2%' }}
            >
              <Button variant="contained" className="ButtonMagentaRecarga">
                {i18n.serviceButtonBanner}
              </Button>
            </a>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box className="boxBannerImg">
            <img src={bannerRecargaCelular} alt="telefono celular" className="imgBannerRecargaCelular" />
          </Box>
        </Grid>
      </Grid>
      <Grid>
        <Box className="boxStepService">
          <Typography variant="h3" fontWeight={800} className="lettersGrey titleStepService">
            {i18n.serviceStepTitle}
          </Typography>
          <Typography variant="p" fontSize={25} className="lettersGrey">
            {i18n.serviceStepText}
            <span className="serviceBoldText">{i18n.serviceStepSubtitle}</span>
          </Typography>
        </Box>
      </Grid>
      <Box className="boxContainerIcons">
        <Grid container>
          <Grid item xs={12} md={4} mb={5} className="gridIcons">
            <Box className="boxRecargaCelular">
              <img src={iconContador} alt="seleccionando el servicio a pagar" />
              <Typography fontWeight={700}>{i18n.rechargeNumberOne}</Typography>
              <Typography variant="p" fontSize={20}>{i18n.serviceCompany}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} mb={5} className="gridIcons">
            <Box className="boxRecargaCelular">
              <img src={iconDocumento} alt="validacion de indentidad" />
              <Typography fontWeight={700}>{i18n.rechargeNumberTwo}</Typography>
              <Typography variant="p" fontSize={20}>{i18n.serviceValidation}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} mb={5} className="gridIcons">
            <Box className="boxRecargaCelular">
              <img src={iconAnotador} alt="mano con dedo pulgar arriba" />
              <Typography fontWeight={700}>{i18n.rechargeNumberThree}</Typography>
              <Typography variant="p" fontSize={20}>{i18n.serviceFinished}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item md={2} />
        <Grid item xs={12} md={8} mt={4} className="boxCenter">
          <Box className="boxCenter">
            <Box className="boxCenter">
              <a
                href={PAGOSERVICIOS_LINK}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit', paddingTop: '2%' }}
              >
                <Button variant="contained" className="ButtonMagentaRecarga buttonSimulate">
                  {i18n.serviceButtonCredit}
                </Button>
              </a>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2} />
      </Grid>
      <Typography variant="h6" fontWeight={600} className="lettersMagentaNew" mb={3} mt={4}>
        {i18n.serviceToCellphoneRecharge}
        <a href={route.cellphoneRecharge} fontWeight={600} className="lettersMagentaNew">
          {i18n.homeLinkHelp}
        </a>
      </Typography>
      <LegalFooter footerName="credicuotas_servicios" />
    </>
  );
};

export default Services;
