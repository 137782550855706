import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Box, Grid } from '@mui/material';
import leftArrow from '../../assests/images/payServices/leftArrow.svg';
import rightArrow from '../../assests/images/payServices/rightArrow.svg';
import '../../assests/css/sliderStyle.css';

const CustomSlider = ({ children, customSettings }) => {
  const [settings, setSettings] = useState();
  const customSliderRef = useRef();

  const getPrevArrow = () => (
    <img src={leftArrow} alt="Izquierda" />
  );

  const getNextArrow = () => (
    <img src={rightArrow} alt="Derecha" />
  );

  const init = () => {
    const settingsNew = customSettings;
    settingsNew.nextArrow = getNextArrow();
    settingsNew.prevArrow = getPrevArrow();
    setSettings(settingsNew);
  };

  useEffect(() => { init(); }, []);

  return (
    <Box>
      {settings && (
        <Box className="sliderBox">
          <Grid container>
            <Grid item xs={2} lg={1} className="flexCenter" />
            <Grid item xs={8} lg={10}>
              <Slider {...settings} ref={customSliderRef}>
                { children }
              </Slider>
            </Grid>
            <Grid item xs={2} lg={1} className="flexCenter" />
          </Grid>
        </Box>
      )}
    </Box>
  );
};

CustomSlider.propTypes = {
  children: PropTypes.node.isRequired,
  customSettings: PropTypes.shape({
    nextArrow: PropTypes.node,
    prevArrow: PropTypes.node,
  }).isRequired,
};

export default CustomSlider;
