import { useLocation, Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import '../../assests/css/styledBreadcrumbs.css';

const separator = '>';

const BlogBreadcumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={separator} className="breadcrumbs">
      <Link component={RouterLink} to="/" color="inherit">
        Home
      </Link>
      {pathnames.length > 0 && (
        <Link component={RouterLink} to="/blog" color="inherit">
          Blog
        </Link>
      )}
    </Breadcrumbs>
  );
};

export default BlogBreadcumbs;
