import {
  Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@mui/material';
import parse from 'html-react-parser';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fillMessageWith } from '../../utils/functionUtils';
import i18n from '../common/i18n';
import { AUTOGESTION, getEnv } from '../../api/env';

const CLIENT_LINK = getEnv(AUTOGESTION);

const helpPrepaidCard = () => (
  <>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpPrepaidCardFirstQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpPrepaidCardFirstAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpPrepaidCardSecondQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpPrepaidCardSecondAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpPrepaidCardThird3Question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpPrepaidCardThird3Answer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpPrepaidCardFourthQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpPrepaidCardFourthAnswerShort}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpPrepaidCardFifthQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpPrepaidCardFifthAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpPrepaidCardSixthQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">
          {parse(fillMessageWith(i18n.helpPrepaidCardSixthAnswer, CLIENT_LINK))}
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpPrepaidCardSeventhQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpPrepaidCardSeventhAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpPrepaidCardEigthQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpPrepaidCardEigthAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpPrepaidCardNineQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpPrepaidCardNineAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
  </>
);

export default helpPrepaidCard;
