import {
  Box, Grid, Accordion, AccordionDetails, AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet-async';
import i18n from '../common/i18n';
import Banner from '../common/Banner';
import shopRequirement from '../../assests/images/icons/BNPL-32.png';
import documentRequirement from '../../assests/images/home/recurso-home-15.png';
import moneyRequirement from '../../assests/images/home/recurso-home-17.png';
import bannerImage from '../../assests/images/home/bannerHome.svg';

const BuyNowPayLater = () => (
  <>
    <Helmet>
      <title>{i18n.bnplTitle}</title>
      <meta name="description" content={i18n.homeBnpl} />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
    {/* banner */}
    <Banner title={i18n.bnplTitle} subtitle={i18n.bnplSubtitle} backgroundImage={bannerImage} />
    {/* cómo hacer compras onlines con Credicuotas */}
    <Grid container className="gridSpaces">
      <Grid item xs={1} />
      <Grid item xs={10} md={12}>
        <h1 className="h1Home">{i18n.bnplHow}</h1>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <Box className="requirementsBox">
              <img src={shopRequirement} alt="shopRequirement" width="170px" />
            </Box>
            <Box className="pBoxHome pBoxHomeRequirements">
              <h5 className="textHome pRequirements">{i18n.bnplFirstRequirement}</h5>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box className="requirementsBox">
              <img src={documentRequirement} alt="documentRequirement" width="300px" />
            </Box>
            <Box className="pBoxHome pBoxHomeRequirements">
              <h5 className="textHome pRequirements">{i18n.bnplSecondRequirement}</h5>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box className="requirementsBox">
              <img src={moneyRequirement} alt="moneyRequirement" width="210px" />
            </Box>
            <Box className="pBoxHome pBoxHomeRequirements">
              <h5 className="textHome pRequirements">{i18n.bnplThirdRequirement}</h5>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
    {/* preguntas o datos básicos */}
    <Grid container className="gridSpaces">
      <Grid item xs={1} />
      <Grid item xs={10}>
        <h1 className="h1Home leftSide marginBottomQuestions">{i18n.faq}</h1>
        <Accordion className="marginBottomQuestions">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h2 className="h2Home">{i18n.bnplFirstQuestion}</h2>
          </AccordionSummary>
          <AccordionDetails>
            <h5 className="textHome">
              {i18n.bnplFirstAnswerOne}
            </h5>
            <h5 className="textHome">
              {i18n.bnplFirstAnswerTwo}
            </h5>
            <h5 className="textHome">
              {i18n.bnplFirstAnswerThree}
            </h5>
          </AccordionDetails>
        </Accordion>
        <Accordion className="marginBottomQuestions">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h2 className="h2Home">{i18n.bnplSecondQuestion}</h2>
          </AccordionSummary>
          <AccordionDetails>
            <h5 className="textHome">
              {i18n.bnplSecondAnswer}
            </h5>
          </AccordionDetails>
        </Accordion>
        <Accordion className="marginBottomQuestions">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h2 className="h2Home">{i18n.bnplThirdQuestion}</h2>
          </AccordionSummary>
          <AccordionDetails>
            <h5 className="textHome">
              {i18n.bnplThirdAnswer}
            </h5>
          </AccordionDetails>
        </Accordion>
        <Accordion className="marginBottomQuestions">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h2 className="h2Home">{i18n.bnplFourthQuestion}</h2>
          </AccordionSummary>
          <AccordionDetails>
            <h5 className="textHome">
              {i18n.bnplFourthAnswer}
            </h5>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  </>
);

export default BuyNowPayLater;
