import { useEffect } from 'react';
import {
  Box, Button, Grid, Typography, useMediaQuery,
} from '@mui/material';
import i18n from '../common/i18n';
import bannerImgLady from '../../assests/images/payServices/chica-telefono.png';
import imgHand from '../../assests/images/payServices/pago-facturas.png';
import imgRecharge from '../../assests/images/payServices/recarga-celular.png';
import imgBankCardMobile from '../../assests/images/payServices/bankCardMobile.png';
import faceBiometry from '../../assests/images/home/faceBiometry.svg';
import okeyHand from '../../assests/images/home/okeyHand.png';
import greyVibe from '../../assests/images/payServices/onda-gris.svg';
import tic from '../../assests/images/home/tic.png';
import { paymentServiceList, sliderSettings } from '../../constants/payServicesConstants';
import '../../assests/css/payServices.css';
import CustomSlider from '../common/CustomSlider';
import PayServiceSliderCard from '../PayServiceSliderCard';
import { RECHARGE_URL, SERVICE_URL, getEnv } from '../../api/env';

/* eslint "arrow-body-style": "off" */
const PayServices = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const matchesMobile = useMediaQuery('(max-width: 1201px)');

  const onclickPayNow = () => {
    window.open(getEnv(SERVICE_URL), '_blank', 'noopener,noreferrer');
  };

  const onclickRechargeNow = () => {
    window.open(getEnv(RECHARGE_URL), '_blank', 'noopener,noreferrer');
  };

  return (
    <Box className="payServicesBody">
      <Box className="mainBoxPayServices flexCenter">
        <Grid container className="gridMainBoxPayServices">
          {matchesMobile ? (
            <Grid item xs={12} paddingX={3} marginTop={-1}>
              <Typography variant="h3" component="h1" fontWeight={900} paddingBottom={3} className="titlePayServices">
                {i18n.payServicesTitle}
              </Typography>
              <Typography variant="p" className="subtitlePayServices">
                {i18n.payServicesSubtitleFirst}
                <br />
                {i18n.payServicesSubtitleSecond}
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item md={8} paddingLeft={10} className="textLeft" mt={2}>
                <Typography variant="h3" component="h1" fontWeight={700} paddingBottom={3} className="titlePayServices">
                  {i18n.payServicesTitle}
                </Typography>
                <Typography variant="p" className="subtitlePayServices">
                  {i18n.payServicesSubtitleFirst}
                  <br />
                  {i18n.payServicesSubtitleSecond}
                </Typography>
              </Grid>
              <Grid item md={4} className="flexCenter" paddingRight={10} mt={2}>
                <img src={bannerImgLady} alt="lady tablet" className="imgLadyTablet" />
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      { /* ¿Qué podés pagar? */}
      <Box className="payServicesBoxWhatToPay">
        <Box className="payServicesBoxWhatToPayTitle">
          <Typography variant="h3" fontWeight={600} color="#383838">
            {i18n.payServicesWhatToPay}
          </Typography>
          <Typography variant="h4" mt={1} fontWeight={400} className="payServicesSubtitleWhatToPay">
            {i18n.payServicesAvailable}
          </Typography>
        </Box>
        <CustomSlider customSettings={sliderSettings}>
          {paymentServiceList.map((service) => (
            <PayServiceSliderCard service={service} />
          ))}
        </CustomSlider>
      </Box>

      { /* Pagá en cuotas tus facturas de servicios */}
      <Box className="payServicesPayTickets flexCenter textLeft">
        <Grid container>
          <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }} className="payServicesGridItemPayments">
            <Typography variant="h3" className="payServicesBenefitsTitle">
              {i18n.payServicesPayments}
            </Typography>
            <Typography variant="h4" mt={3} fontWeight={400} className="payServicesSubtitleWhatToPay">
              {i18n.payServicesBenefits}
            </Typography>
            <Box className="buttonPayNow">
              <Button variant="contained" onClick={onclickPayNow} className="ButtonMagenta buttonMoreInfo">
                {i18n.payServicesPayNow}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4} order={{ xs: 1, lg: 2 }} className="flexCenter">
            <Box className="payServicesBoxHandImage flexCenter">
              <Box>
                <img src={imgHand} alt="Pago servicios" className="imgHand" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      { /* Recargá tu celular y pagalo en 30 días */}
      <Box className="payServicesRecharge flexCenter textLeft">
        <Grid container>
          <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }} className="flexCenter">
            <Box className="payServicesBoxRechargeBackground flexCenter">
              <Box>
                <Box>
                  <img src={imgRecharge} alt="Recarga de celular" className="imgRecharge" />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }} className="payServicesGridItemRecharge">
            <Typography variant="h3" mt={1} className="payServicesBenefitsTitle">
              {i18n.payServicesRecharge}
            </Typography>
            <Typography
              variant="h4"
              mt={3}
              fontWeight={400}
              className="payServicesSubtitleWhatToPay"
            >
              {i18n.payServicesRechargeSubtitle}
            </Typography>
            <Box className="buttonPayNow">
              <Button variant="contained" onClick={onclickRechargeNow} className="ButtonMagenta buttonMoreInfo">
                {i18n.payServicesRechargeNow}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      { /* ¿Cómo funciona? */}
      <Box className="payServicesHowWorks">
        <Typography variant="h3" fontWeight={600} color="#383838">
          {i18n.payServicesHowWorks}
        </Typography>
        <Typography variant="h5" fontWeight={500} paddingTop={3} color="#383838">
          {i18n.payServicesSimple}
        </Typography>
        <Grid container className="payServicesGridContainerBoxCircle">
          <Grid item xs={12} md={4}>
            <Box className="payServicesFlexBoxCircle payServicesFirstGridContainerBoxCircle">
              <Box className="payServicesBoxCircle payServicesFlexBoxCircle">
                <img src={imgBankCardMobile} alt="Elegí tu compañía" className="imgBoxCircle" />
              </Box>
            </Box>
            <Box mt={1}>
              <Typography variant="p" className="payServicesTypographyBoxCircleNumber">
                {i18n.payServicesFirstItem}
              </Typography>
            </Box>
            <Box className="payServicesFlexBoxCircle">
              <Typography variant="p" className="payServicesTypographyBoxCircle">
                {i18n.payServicesSelectCompany}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="payServicesFlexBoxCircle payServicesGridContainerBoxCircle">
              <Box className="payServicesBoxCircle payServicesFlexBoxCircle">
                <img src={faceBiometry} alt="Validá tu identidad" className="imgBoxCircleFace" />
              </Box>
            </Box>
            <Box mt={1}>
              <Typography variant="p" className="payServicesTypographyBoxCircleNumber">
                {i18n.payServicesSecondItem}
              </Typography>
            </Box>
            <Box className="payServicesFlexBoxCircle">
              <Typography variant="p" className="payServicesTypographyBoxCircle">
                {i18n.payServicesValidate}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="payServicesFlexBoxCircle payServicesGridContainerBoxCircle">
              <Box className="payServicesBoxCircle payServicesFlexBoxCircle">
                <img src={okeyHand} alt="Listo!" className="imgBoxCircle" />
              </Box>
            </Box>
            <Box mt={1}>
              <Typography variant="p" className="payServicesTypographyBoxCircleNumber">
                {i18n.payServicesThirdItem}
              </Typography>
            </Box>
            <Box className="payServicesFlexBoxCircle">
              <Typography variant="p" className="payServicesTypographyBoxCircle">
                {i18n.payServicesFinished}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      { /* ¿Por qué te conviene pagar con credicuotas? */}
      <Box className="payServicesBoxVibe">
        <img src={greyVibe} alt="Onda gris" className="imgGreyVibe" />
        <Box className="payServicesBoxVibeTitle">
          <Typography variant="h3" className="payServicesBoxVibeBoxTypography">
            {i18n.payServicesWhyCC}
          </Typography>
        </Box>
        <Box className="payServicesBoxVibeBackground" />
      </Box>
      <Box className="payServicesWhyCC">
        <Box className="payServicesWhyBox">
          <Box className="payServicesWhyBoxInside">
            <img src={tic} alt="tic" className="imgTick" />
            <Typography variant="h6" className="payServicesWhyTypography">
              {i18n.payServicesWeAreSimple}
            </Typography>
          </Box>
        </Box>
        <Box className="payServicesWhyBox">
          <Box className="payServicesWhyBoxInside">
            <img src={tic} alt="tic" className="imgTick" />
            <Typography variant="h6" className="payServicesWhyTypography">
              {i18n.payServicesFinancePayments}
            </Typography>
          </Box>
        </Box>
        <Box className="payServicesWhyBox">
          <Box className="payServicesWhyBoxInside">
            <img src={tic} alt="tic" className="imgTick" />
            <Typography variant="h6" className="payServicesWhyTypography">
              {i18n.payServicesMoreBenefits}
            </Typography>
          </Box>
        </Box>
      </Box>

    </Box>
  );
};

export default PayServices;
