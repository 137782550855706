import { useEffect } from 'react';
import {
  Box, Button, Grid, Accordion, AccordionDetails, AccordionSummary, Typography, useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18n from '../common/i18n';
import { route } from '../navigation/routes';
import WtCalculator from '../calculator/WtCalculator';
import checkListPaper from '../../assests/images/home/checkListPaper.svg';
import faceBiometry from '../../assests/images/home/faceBiometry.svg';
import document from '../../assests/images/home/document.svg';
import wallet from '../../assests/images/home/wallet.svg';
import okeyHand from '../../assests/images/home/okeyHand.png';
import commerceBnpl from '../../assests/images/home/commerceBnpl.png';
import commerceWc from '../../assests/images/home/commerceWc.png';
import money from '../../assests/images/home/money.png';
import moto from '../../assests/images/home/moto.png';
import telephone from '../../assests/images/home/telephone.png';
import monitor from '../../assests/images/home/monitor.png';
import tic from '../../assests/images/home/tic.png';
import imgHand from '../../assests/images/payServices/pago-facturas.png';
import '../../assests/css/generalStyle.css';
import {
  ORIGINACION_WT, getEnv,
} from '../../api/env';
import HeaderTags from '../common/HeaderTags';

const Home = () => {
  const ORIGINACION_WT_LINK = getEnv(ORIGINACION_WT);
  const matchesMobile = useMediaQuery('(max-width: 1201px)');
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <HeaderTags title={i18n.metaTitleHome} content={i18n.metaDescriptionHome} scriptMarkUpSchema />
      {/* bnpl, prestamos personales y tiendas fisicas */}
      <Box className="boxCalculatorHome" mt={10}>
        <Grid container className="flexStartVertical">
          <Grid item xs={10} lg={6} xl={5} className="gridHome">
            <Box className="boxTitleHome">
              <Typography variant="h1" fontWeight={800} className="lettersWhiteOnly">
                {i18n.homeCreditNow}
              </Typography>
              <Box className="boxSubtitleHome widthBoxTypography">
                <Typography variant="h4" className="lettersWhiteOnly widthBoxTypography">
                  {i18n.homeCreditNowSubtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={10} lg={5} xl={5} className="marginCalculator">
            <WtCalculator />
          </Grid>
        </Grid>
      </Box>
      {/* cómo saco un préstamo */}
      <Grid container mt={6}>
        <Grid item xs={1} lg={1.3} />
        <Grid item xs={10} lg={9.4}>
          <Typography variant="h3" fontWeight={800} className="lettersGrey">{i18n.homeHowToDo}</Typography>
          <Grid container className="spaceMedium">
            <Grid item xs={12} lg={4} className="flexCircleHome">
              <Box>
                <Box className="circleBox flexImage">
                  <img src={checkListPaper} alt="checkListPaper" className="imgCheckListPaper" />
                </Box>
                <Box className="flexCenter widthBoxTypography">
                  <Box className="boxSmallTypography widthBoxTypography">
                    <Typography variant="h6" fontWeight={700} className="lettersMagentaVersionThree">
                      {i18n.homeEnterYourData}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} className="flexCenter">
              <Box className="circleBox flexImage spaceMobile">
                <img src={faceBiometry} alt="faceBiometry" className="imgFaceBiometry" />
              </Box>
              <Box className="flexCenter widthBoxTypography">
                <Box className="boxSmallTypography widthBoxTypography">
                  <Typography variant="h6" fontWeight={700} className="lettersMagentaVersionThree">
                    {i18n.homeValidateIdentity}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} className="flexLastCircleHome">
              <Box>
                <Box className="circleBox flexImage spaceMobile">
                  <img src={okeyHand} alt="okeyHand" className="imgOkeyHand" />
                </Box>
                <Box className="flexCenter widthBoxTypography">
                  <Box className="boxSmallTypography widthBoxTypography">
                    <Typography variant="h6" fontWeight={700} className="lettersMagentaVersionThree">
                      {i18n.homeReceive}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} lg={1.3} />
      </Grid>
      {/* que necesito */}
      <Grid container mt={6} mb={4}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          {
            matchesMobile ? (
              <Box className="boxRequirements flexCenterUp">
                <Box className="flexCenter">
                  <Typography variant="h3" fontWeight={800} className="lettersMagentaNew">
                    {i18n.homeWhatNeed}
                  </Typography>
                  <Box className="flexCenter spaceTypography widthBoxTypography">
                    <img src={document} alt="document" width="120px" />
                    <Box className="flexCenter widthBoxTypography">
                      <Box className="boxMediumTypography">
                        <Typography variant="body1" fontWeight={600} className="lettersVersionTwo">
                          {i18n.homeRequirementAdult}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="flexCenter spaceBetween widthBoxTypography">
                    <img src={telephone} alt="telephone" width="140px" />
                    <Box className="flexCenter widthBoxTypography">
                      <Box className="boxMediumTypography">
                        <Typography variant="body1" fontWeight={600} className="lettersVersionTwo">
                          {i18n.homeRequirementTelephone}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="flexCenter spaceBetween widthBoxTypography">
                    <img src={wallet} alt="wallet" width="140px" />
                    <Box className="flexCenter widthBoxTypography">
                      <Box className="boxMediumTypography">
                        <Typography variant="body1" fontWeight={600} className="lettersVersionTwo">
                          {i18n.homeRequirementWhite}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className="boxRequirements flexCenter">
                <Grid container>
                  <Grid item xs={0} lg={0.3} />
                  <Grid item lg={2.91} xl={2} className="flexWhatNeed">
                    <Typography variant="h3" fontWeight={800} className="lettersMagentaNew">
                      {i18n.homeWhatNeed}
                    </Typography>
                  </Grid>
                  <Grid item xl={0.6} />
                  <Grid item lg={2.93} className="flexCenter">
                    <img src={document} alt="document" width="120px" />
                    <Box className="flexCenter">
                      <Box className="boxMediumTypography">
                        <Typography variant="body1" fontWeight={600} className="lettersVersionTwo">
                          {i18n.homeRequirementAdult}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={2.93} className="flexCenter">
                    <img src={telephone} alt="telephone" width="140px" />
                    <Box className="flexCenter">
                      <Box className="boxMediumTypography">
                        <Typography variant="body1" fontWeight={600} className="lettersVersionTwo">
                          {i18n.homeRequirementTelephone}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={2.93} className="flexCenter">
                    <img src={wallet} alt="wallet" width="140px" className="flexCenter" />
                    <Box className="flexCenter widthBoxTypography">
                      <Box className="boxMediumTypography">
                        <Typography variant="body1" fontWeight={600} className="lettersVersionTwo">
                          {i18n.homeRequirementWhite}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )
          }
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {/* bnpl */}
      <Box mt={6} className="boxBnpl ocultar">
        {
          matchesMobile ? (
            <Box className="flexCenterUp">
              <img src={commerceBnpl} alt="commerceBnpl" width="350px" className="flexCenter" />
              <Box className="boxHomeBnpl spaceMedium widthBoxTypography">
                <Typography variant="h3" fontWeight={800} className="lettersGrey">{i18n.homeBnpl}</Typography>
                <Box className="spaceMobile widthBoxTypography">
                  <Typography variant="p" fontSize={22}>{i18n.homeSloganBnpl}</Typography>
                </Box>
              </Box>
              <Box className="spaceMobile widthBoxTypography">
                <Link to={route.buyNowPayLater} style={{ textDecoration: 'none' }}>
                  <Button variant="contained" className="ButtonMagenta buttonMoreInfo">
                    {i18n.homeMoreInformation}
                  </Button>
                </Link>
              </Box>
            </Box>
          ) : (
            <Grid container>
              <Grid item xs={1} />
              <Grid xs={3} mt={3}>
                <img src={commerceBnpl} alt="commerceBnpl" width="440px" className="flexCenter" />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={4} mt={5} className="textLeft">
                <Typography variant="h3" fontWeight={800} className="lettersGrey">{i18n.homeBnpl}</Typography>
                <Box className="boxHomeBnpl spaceParagraph">
                  <Typography variant="p" fontSize={24}>{i18n.homeSloganBnpl}</Typography>
                </Box>
                <Box className="spaceBetween">
                  <Link to={route.buyNowPayLater} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" className="ButtonMagenta buttonMoreInfo">
                      {i18n.homeMoreInformation}
                    </Button>
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={2} />
            </Grid>
          )
        }
      </Box>
      {/* wc */}
      <Box className="boxWc">
        {
          matchesMobile ? (
            <Box className="flexCenterUp">
              <Box className="boxHomeBnpl widthBoxTypography">
                <Typography variant="h3" fontWeight={800} className="lettersGrey">
                  {i18n.homePhysicalCommerce}
                </Typography>
                <Box className="spaceMobile widthBoxTypography">
                  <Typography variant="p" fontSize={22}>{i18n.homeSloganPhysicalCommerce}</Typography>
                </Box>
              </Box>
              <Box className="spaceMobile widthBoxTypography">
                <Link to={route.physicalCommerce} style={{ textDecoration: 'none' }}>
                  <Button variant="contained" className="ButtonMagenta buttonMoreInfo">
                    {i18n.homeMoreInformation}
                  </Button>
                </Link>
              </Box>
              <Box className="spaceMedium">
                <img src={commerceWc} alt="commerceWc" width="350px" className="flexCenter" />
              </Box>
            </Box>
          ) : (
            <Grid container>
              <Grid item lg={1.3} />
              <Grid item lg={4.8} xl={4.2} mt={5} className="textLeft">
                <Box className="boxHomeWc">
                  <Typography variant="h3" fontWeight={800} className="lettersGrey">
                    {i18n.homePhysicalCommerce}
                  </Typography>
                </Box>
                <Box className="boxHomeBnpl spaceParagraph">
                  <Typography variant="p" fontSize={24}>{i18n.homeSloganPhysicalCommerce}</Typography>
                </Box>
                <Box className="spaceBetween">
                  <Link to={route.physicalCommerce} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" className="ButtonMagenta buttonMoreInfo">
                      {i18n.homeMoreInformation}
                    </Button>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={0.7} xl={1.2} />
              <Grid lg={4.5} xl={4} mt={5}>
                <img src={commerceWc} alt="commerceWc" width="470px" />
              </Grid>
              <Grid item lg={1} xl={1.3} />
            </Grid>
          )
        }
      </Box>
      {/* Pagá tus servicios */}
      <Box className="boxPayServicesHome">
        <Grid container>
          <Grid item xs={12} lg={6} className="flexCenter">
            <Box className="payServicesBoxRechargeBackground flexCenter">
              <Box>
                <Box>
                  <img src={imgHand} alt="Recarga de celular" className="imgRecharge" />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={5} className="homePayServicesBoxTitle textLeft">
            <Typography variant="h3" fontWeight={800} className="lettersGrey">
              {i18n.homePayServicesTitle}
            </Typography>
            <Box className="homePayServicesBoxSubTitle">
              <Typography variant="p" fontSize={22}>
                {i18n.homePayServicesSubtitle}
              </Typography>
            </Box>
            <Box className="buttonPayNow">
              <Link to={route.payServices} style={{ textDecoration: 'none' }}>
                <Button variant="contained" className="ButtonMagenta buttonMoreInfo">
                  {i18n.payServicesPayNow}
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* para qué lo podés usar */}
      <Box mt={6}>
        <Typography variant="h3" fontWeight={800} className="lettersMagentaNew" mb={5}>
          {i18n.homeForWhat}
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={0.5} md={1} lg={1} />
        <Grid item xs={11} md={10} lg={10}>
          <Grid container>
            <Grid item xs={12} md={12} lg={5.75}>
              <Grid container className="flexCenter">
                <Grid item xs={5.5} md={4} lg={5.5} className="boxForWhat flexCenter" backgroundColor="#FFC761">
                  <a
                    href={route.motorcycle}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    className="flexCenter"
                  >
                    <img src={moto} alt="moto" className="imgMoto" />
                    <Box className="boxSelectorWidth">
                      <Typography variant="h6">
                        {i18n.homeBuyMoto}
                      </Typography>
                    </Box>
                  </a>
                </Grid>
                <Grid item xs={1} md={1} lg={1} />
                <Grid item xs={5.5} md={4} lg={5.5} className="boxForWhat flexCenter" backgroundColor="#FFDFA5">
                  <img src={telephone} alt="telephone" className="imgTelephone" />
                  <Box className="boxSelectorWidth">
                    <Typography variant="h6">{i18n.homeCellphone}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={0.5} />
            <Grid item xs={12} md={12} lg={5.75}>
              <Grid container className="flexCenter secondBoxesImages">
                <Grid item xs={5.5} md={4} lg={5.5} className="boxForWhat flexCenter" backgroundColor="#EFBDDB">
                  <a
                    href={route.electro}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    className="flexCenter"
                  >
                    <img src={monitor} alt="monitor" className="imgMonitor" />
                    <Box className="boxSelectorWidth">
                      <Typography variant="h6">{i18n.homeBuyElectro}</Typography>
                    </Box>
                  </a>
                </Grid>
                <Grid item xs={1} md={1} lg={1} />
                <Grid item xs={5.5} md={4} lg={5.5} className="boxForWhat flexCenter" backgroundColor="#F9CFD4">
                  <img src={money} alt="money" className="imgMoney" />
                  <Box className="boxSelectorWidth">
                    <Typography variant="h6">{i18n.homePayService}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.5} md={1} lg={1} />
      </Grid>
      {/* por qué elegirnos */}
      <Box className="boxWhyUs" mt={8}>
        <Typography variant="h3" fontWeight={800} className="lettersWhiteOnly paddingTitle">
          {i18n.homeWithUs}
        </Typography>
        <Grid container>
          <Grid item xs={1} md={3.2} />
          <Grid item xs={10} md={6}>
            <Box className="whyBox whyBoxTopOne">
              <img src={tic} alt="tic" className="imgTick" />
              <Box className="whyBoxInside textLeft">
                <Typography variant="p" fontWeight={900} fontSize={24} className="lettersWhiteOnly">
                  {i18n.homeWeAreSimple}
                </Typography>
                <Box className="widthBoxTypography textLeft">
                  <Typography variant="p" fontSize={20} className="lettersWhiteOnly">
                    {i18n.homeSloganSimple}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="whyBox whyBoxTop">
              <img src={tic} alt="tic" className="imgTick" />
              <Box className="whyBoxInside textLeft">
                <Typography variant="p" fontWeight={900} fontSize={24} className="lettersWhiteOnly">
                  {i18n.homeWeFinanceYou}
                </Typography>
                <Box className="widthBoxTypography textLeft">
                  <Typography variant="p" fontSize={20} className="lettersWhiteOnly">
                    {i18n.homeSloganWeFinanceYou}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="whyBox whyBoxTop">
              <img src={tic} alt="tic" className="imgTick" />
              <Box className="whyBoxInside textLeft">
                <Typography variant="p" fontWeight={900} fontSize={24} className="lettersWhiteOnly">
                  {i18n.homeThinkOnYou}
                </Typography>
                <Box className="widthBoxTypography textLeft">
                  <Typography variant="p" fontSize={20} className="lettersWhiteOnly">
                    {i18n.homeSloganThinkOnYou}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1} md={2} />
        </Grid>
      </Box>
      {/* preguntas frecuentes */}
      <Typography variant="h3" fontWeight={700} className="lettersGrey" mt={5}>{i18n.faq}</Typography>
      <Grid container mt={3} mb={1}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">{i18n.homeFirstQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="textLeft">
              <Typography component="p" fontSize={18}>
                {i18n.homeFirstAnswerOne}
                <a
                  href={ORIGINACION_WT_LINK}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {i18n.homeAnswerCTAOrigination}
                </a>
                {i18n.homeFirstAnswerTwo}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">{i18n.homeSecondQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="textLeft">
              <Typography component="p" fontSize={18}>
                {i18n.homeSecondAnswerOne}
                <a
                  href={ORIGINACION_WT_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {i18n.homeAnswerCTAOrigination}
                </a>
                {i18n.homeSecondAnswerTwo}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">{i18n.homeThirdQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="textLeft">
              <Typography component="p" fontSize={18}>
                {i18n.homeThirdAnswer}
                <a
                  href={ORIGINACION_WT_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {i18n.homeAnswerCTAOriginationHere}
                </a>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomLastQuestion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">{i18n.homeFourQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="textLeft paddingBottomLastQuestion">
              <Typography component="p" fontSize={18}>
                {i18n.homeFourAnswer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={1} mb={15} />
      </Grid>
      <Typography variant="h5" fontWeight={600} className="lettersMagentaNew" mb={3}>
        {i18n.homeMoreQuestion}
        <a href={route.help} fontWeight={600} className="lettersMagentaNew">
          {i18n.homeLinkHelp}
        </a>
      </Typography>
    </>
  );
};

export default Home;
