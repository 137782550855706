import { useEffect } from 'react';
import parse from 'html-react-parser';
import {
  Box, Button,
  Grid, Accordion, AccordionDetails, AccordionSummary,
  Typography, useMediaQuery,
} from '@mui/material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18n from '../common/i18n';
import { route } from '../navigation/routes';
import stepLogin from '../../assests/images/prepaidCard/step-login.png';
import stepPause from '../../assests/images/prepaidCard/step-pause.svg';
import stepBlock from '../../assests/images/prepaidCard/step-block.svg';
import benefitCash from '../../assests/images/prepaidCard/benefit-cash.svg';
import benefitClock from '../../assests/images/prepaidCard/benefit-clock.svg';
import benefitFree from '../../assests/images/prepaidCard/benefit-free.svg';
import benefitCommerce from '../../assests/images/prepaidCard/benefit-commerce.svg';
import stepInstallmentPlan from '../../assests/images/prepaidCard/step-installment-plan.svg';
import stepProofOfLife from '../../assests/images/prepaidCard/step-proof-of-life.svg';
import stepDisburseCard from '../../assests/images/prepaidCard/step-disburse-card.svg';
import stepOkHand from '../../assests/images/prepaidCard/step-ok-hand.png';
import cellphone from '../../assests/images/prepaidCard/cellphone.png';
import '../../assests/css/prepaidCardStyle.css';

import {
  ORIGINACION_WT, getEnv,
} from '../../api/env';
import HeaderTags from '../common/HeaderTags';

const PrepaidCard = () => {
  const ORIGINACION_WT_LINK = getEnv(ORIGINACION_WT);
  const matchesMobile = useMediaQuery('(max-width: 1201px)');

  let theme = createTheme({
    palette: {
      magenta: {
        main: '#DF1365',
        contrastText: '#fff',
      },
    },
  });
  theme = responsiveFontSizes(theme);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const pushEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        items: [{
          item_name: 'Tradicional Web',
        }],
      },
    });
  };

  return (
    <>
      <HeaderTags title={i18n.metaTitlePrepaidCard} content={i18n.metaDescriptionPrepaidCard} scriptMarkUpSchema />

      {/* Tarjeta Credicuotas */}
      <Box className="boxBanner">
        <Box className="boxTitleBanner">
          <Typography className="textTitleBanner" variant="h1">
            {i18n.prepaidCardTitle}
          </Typography>
          <Typography className="textSubTitleBanner">
            {i18n.prepaidCardSubtitle}
          </Typography>
          <a
            href={ORIGINACION_WT_LINK}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Button onClick={pushEvent} variant="contained" className="buttonBanner">
              {i18n.prepaidCardButtonCredit}
            </Button>
          </a>
        </Box>
      </Box>

      <Box mt={matchesMobile ? 8 : 16} />

      {/* ¿Cuáles son sus ventajas? */}
      <Box>
        <Typography className="textTitleBenefits" mb={3}>
          {i18n.prepaidCardForWhatBenefits}
        </Typography>
      </Box>
      <Grid
        container
        justify="space-around"
        style={{
          paddingLeft: matchesMobile ? '10%' : '20%',
          paddingRight: matchesMobile ? '10%' : '20%',
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{
            paddingTop: '4%',
            paddingBottom: '4%',
            paddingLeft: '2%',
            paddingRight: '2%',
          }}
        >
          <img src={benefitCash} alt="benefitCash" className="imgBenefits" />
          <Typography className="textSubTitleBenefits" variant="h3">{i18n.prepaidCardBenefitCashTitle}</Typography>
          <Typography className="textBodyBenefits">{i18n.prepaidCardBenefitCashSubTitle}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            paddingTop: '4%',
            paddingBottom: '4%',
            paddingLeft: '2%',
            paddingRight: '2%',
          }}
        >
          <img src={benefitClock} alt="benefitClock" className="imgBenefits" />
          <Typography className="textSubTitleBenefits" variant="h3">
            {i18n.prepaidCardBenefitPrepaidCardTitle}
          </Typography>
          <Typography className="textBodyBenefits">{i18n.prepaidCardBenefitPrepaidCardSubTitle}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            paddingTop: '4%',
            paddingBottom: '4%',
            paddingLeft: '2%',
            paddingRight: '2%',
          }}
        >
          <img src={benefitFree} alt="benefitFree" className="imgBenefits" />
          <Typography className="textSubTitleBenefits">{i18n.prepaidCardBenefitFreeShippingTitle}</Typography>
          <Typography className="textBodyBenefits">{i18n.prepaidCardBenefitFreeShippingSubTitle}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            paddingTop: '4%',
            paddingBottom: '4%',
            paddingLeft: '2%',
            paddingRight: '2%',
          }}
        >
          <img src={benefitCommerce} alt="benefitCommerce" className="imgBenefits" />
          <Typography className="textSubTitleBenefits">{i18n.prepaidCardBenefitCommerceTitle}</Typography>
          <Typography className="textBodyBenefits">{i18n.prepaidCardBenefitCommerceSubTitle}</Typography>
        </Grid>
      </Grid>

      {/* ¿Como pido mi tarjeta? */}
      <Box className="boxBodyBanner">
        <ThemeProvider theme={theme}>
          <Box className="flexHowGetCard lettersWhiteOnly">
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <Typography className="titleHowGetCard lettersWhiteOnly">
                {i18n.prepaidCardHowGetCardTitle}
              </Typography>
              <Box style={{ padding: matchesMobile ? '0px' : '0px 10%' }}>

                <TimelineItem style={{ padding: '0px 0px 0px 2%' }}>
                  <TimelineOppositeContent />
                  <TimelineSeparator>
                    <TimelineDot style={{
                      padding: '0px', borderStyle: 'hidden', margin: '0px', backgroundColor: '#F5F5F5',
                    }}
                    >
                      <img src={stepInstallmentPlan} alt="Plan de Cuotas" className="imgHowGetCard" />
                    </TimelineDot>
                    <TimelineConnector style={{ backgroundColor: '#FFFFFF', width: '3px', height: '90px' }} />
                  </TimelineSeparator>
                  <TimelineContent
                    style={{ padding: '0px 0px 0px 5%', alignContent: 'baseline' }}
                  >
                    <Typography className="textHowGetCard">{i18n.prepaidCardHowGetCardFirstStep}</Typography>
                  </TimelineContent>
                </TimelineItem>

                <TimelineItem style={{ padding: '0px 0px 0px 2%' }}>
                  <TimelineOppositeContent />
                  <TimelineSeparator>
                    <TimelineDot style={{
                      padding: '0px', borderStyle: 'hidden', margin: '0px', backgroundColor: '#F5F5F5',
                    }}
                    >
                      <img src={stepProofOfLife} alt="Prueba de vida" className="imgHowGetCard" />
                    </TimelineDot>
                    <TimelineConnector style={{ backgroundColor: '#FFFFFF', width: '3px', height: '80px' }} />
                  </TimelineSeparator>
                  <TimelineContent
                    style={{ padding: '0px 0px 0px 5%', alignContent: 'baseline' }}
                  >
                    <Typography className="textHowGetCard">{i18n.prepaidCardHowGetCardSecondStep}</Typography>
                  </TimelineContent>
                </TimelineItem>

                <TimelineItem
                  style={{
                    padding: '0px 0px 0px 2%',
                    alignItems: 'center',
                    border: '2px groove rgba(255, 255, 255, 0.24)',
                    backgroundColor: 'rgba(255, 255, 255, 0.25)',
                    borderRadius: '50px',
                  }}
                >
                  <TimelineOppositeContent />
                  <TimelineSeparator>
                    <TimelineDot style={{
                      padding: '0px', borderStyle: 'hidden', margin: '0px', backgroundColor: '#F5F5F5',
                    }}
                    >
                      <img src={stepDisburseCard} alt="Desembolso en tarjeta" className="imgHowGetCard" />
                    </TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent
                    style={{ padding: '0px 0px 0px 5%', alignContent: 'center', height: '180px' }}
                  >
                    <Typography className="textHowGetCard">{i18n.prepaidCardHowGetCardThirdStep}</Typography>
                  </TimelineContent>
                </TimelineItem>

                <TimelineItem style={{ padding: '0px 0px 0px 2%' }}>
                  <TimelineOppositeContent />
                  <TimelineSeparator>
                    <TimelineConnector style={{ backgroundColor: '#FFFFFF', width: '3px', height: '120px' }} />
                    <TimelineDot style={{
                      padding: '0px', borderStyle: 'hidden', margin: '0px', backgroundColor: '#F5F5F5',
                    }}
                    >
                      <img src={stepOkHand} alt="Listo" className="imgHowGetCard" />
                    </TimelineDot>
                    <TimelineConnector style={{ width: '0px', height: '80px' }} />
                  </TimelineSeparator>
                  <TimelineContent
                    style={{ padding: '0px 0px 0px 5%', alignContent: 'end' }}
                  >
                    <Typography className="textHowGetCard">{i18n.prepaidCardHowGetCardFourthStep}</Typography>
                  </TimelineContent>
                </TimelineItem>

              </Box>
            </Timeline>
          </Box>
        </ThemeProvider>
        <Grid container className="boxMainHowUseCard" justifyContent="space-around">
          <Grid item>
            <div className="parentHowUseCard">
              <div className="divBoxHowUseCard">
                <Box className="boxHowUseCard">
                  <Grid container>
                    <Grid item xs={3} sm={4} />
                    <Grid item xs={9} sm={8}>
                      <Typography className="titleHowUseCard textLeft">
                        {i18n.prepaidCardHowUseCardTitle}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={4} />
                    <Grid item xs={11} sm={8}>
                      <ul className="listHowUseCard">
                        <li className="listItemHowUseCard">
                          <Typography className="textHowUseCard textLeft">
                            {parse(i18n.prepaidCardHowUseCardItemOne)}
                          </Typography>
                        </li>
                        <li className="listItemHowUseCard">
                          <Typography className="textHowUseCard textLeft">
                            {parse(i18n.prepaidCardHowUseCardItemTwo)}
                          </Typography>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <div className="divImgHowUseCard">
                <img src={cellphone} alt="cellphone" className="imgCellphone" />
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box mt={matchesMobile ? 8 : 16} />

      {/* ¿Perdiste o te robaron tu tarjeta? */}
      { matchesMobile ? (
        <Box className="boxStoleOrBroken flexCenterUp spaceAround">
          <Box className="flexCenter">
            <Box className="flexCenter">
              <Typography className="textTitleStoleOrBroken">
                {i18n.prepaidCardStoleOrBrokenTitle}
              </Typography>
            </Box>
            <Box className="flexCenter spaceBetween">
              <Box className="flexCenter">
                <img src={stepLogin} alt="document" width="100px" />
                <Typography className="textStepStoleOrBroken">
                  {i18n.prepaidCardStoleOrBrokenStepOne}
                </Typography>
              </Box>
            </Box>
            <Box className="flexCenter spaceBetween">
              <Box className="flexCenter">
                <img src={stepPause} alt="telephone" width="100px" />
                <Typography className="textStepStoleOrBroken">
                  {i18n.prepaidCardStoleOrBrokenStepTwo}
                </Typography>
              </Box>
            </Box>
            <Box className="flexCenter spaceBetween">
              <Box className="flexCenter">
                <img src={stepBlock} alt="wallet" width="100px" />
                <Typography className="textStepStoleOrBroken">
                  {i18n.prepaidCardStoleOrBrokenStepThree}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="boxStoleOrBroken spaceAround">
          <Grid container justifyContent="space-evenly">

            <Grid item xs={12} md={2.6}>
              <Box>
                <Typography className="textTitleStoleOrBroken">
                  {i18n.prepaidCardStoleOrBrokenTitle}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={2.6}>
              <Grid container justifyContent="space-evenly">
                <Grid item xs={12} md={10} className="flexCenter">
                  <img src={stepLogin} alt="document" width="100px" />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography className="textStepStoleOrBroken">
                    {i18n.prepaidCardStoleOrBrokenStepOne}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={2.6}>
              <Grid container justifyContent="space-evenly">
                <Grid item xs={12} md={10} className="flexCenter">
                  <img src={stepPause} alt="telephone" width="100px" />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography className="textStepStoleOrBroken">
                    {i18n.prepaidCardStoleOrBrokenStepTwo}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={2.6}>
              <Grid container justifyContent="space-evenly">
                <Grid item xs={12} md={10} className="flexCenter">
                  <img src={stepBlock} alt="wallet" width="100px" />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography className="textStepStoleOrBroken">
                    {i18n.prepaidCardStoleOrBrokenStepThree}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Box>
      )}

      <Box mt={matchesMobile ? 8 : 16} />

      {/* preguntas frecuentes */}
      <Typography variant="h3" fontWeight={700} className="lettersGrey" mt={5}>{i18n.faq}</Typography>
      <Grid container mt={3} mb={1}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="textLeft" variant="h5">{i18n.helpPrepaidCardFirstQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="textLeft">
              <Typography component="p" fontSize={18}>{i18n.helpPrepaidCardFirstAnswer}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="textLeft" variant="h5">{i18n.helpPrepaidCardSecondQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="textLeft">
              <Typography component="p" fontSize={18}>{i18n.helpPrepaidCardSecondAnswer}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="textLeft" variant="h5">{i18n.helpPrepaidCardFourthQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="textLeft">
              <Typography component="p" fontSize={18}>{i18n.helpPrepaidCardFourthAnswerLong}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomLastQuestion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="textLeft" variant="h5">{i18n.helpPrepaidCardThird3Question}</Typography>
            </AccordionSummary>
            <AccordionDetails className="textLeft paddingBottomLastQuestion">
              <Typography component="p" fontSize={18}>{i18n.helpPrepaidCardThird3Answer}</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={1} mb={15} />
      </Grid>
      <Typography variant="h5" fontWeight={600} className="lettersMagentaNew" mb={3}>
        {i18n.homeMoreQuestion}
        <a href={route.help} fontWeight={600} className="lettersMagentaNew">
          {i18n.homeLinkHelp}
        </a>
      </Typography>

    </>
  );
};

export default PrepaidCard;
