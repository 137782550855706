import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Header from '../header/Header';
import Footer from '../common/Footer';
import FooterLogos from '../common/FooterLogos';
import Home from '../pages/Home';
import PrepaidCard from '../pages/PrepaidCard';
import Us from '../pages/Us';
import PhysicalCommerce from '../pages/PhysicalCommerce';
import BuyNowPayLater from '../pages/BuyNowPayLater';
import Help from '../pages/Help';
import Wt from '../pages/Wt';
import Blog from '../pages/Blog';
import BlogNote from '../blog/BlogNote';
import Jobs from '../pages/Jobs';
import Investors from '../pages/Investors';
import FinanceUserInformation from '../pages/FinanceUserInformation';
import ConsumerProtection from '../pages/ConsumerProtection';
import CancelCredit from '../pages/CancelCredit';
import Whatsapp from '../pages/Whatsapp';
import Uber from '../pages/Uber';
import Recharge from '../pages/Seac';
import CellphoneRecharge from '../pages/CellphoneRecharge';
import Services from '../pages/Services';
import PayServices from '../pages/payServices';
import { route } from './routes';

import {
  ENVIRONMENT, getEnv,
} from '../../api/env';
import Branches from '../pages/Branches';
import NotFound from '../pages/NotFound';
import LegalFooter from '../common/LegalFooter';
import UnsubscribeInformation from '../pages/UnsubscribeInformation';
import HubRenewers from '../pages/HubRenewers';
import Contracts from '../pages/Contracts';
import Motorcycle from '../pages/Motorcycle';
import LandingElectro from '../pages/LandingElectro';
import LandingRefurbish from '../pages/LandingRefurbish';

const ENV = getEnv(ENVIRONMENT);
let theme = createTheme({
  root: {
    color: '#E61A5F',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid red',
      },
    },
  },
});

theme = responsiveFontSizes(theme);

const AppRouter = () => (
  <BrowserRouter>
    <nav>
      <Header />
    </nav>
    <HelmetProvider>
      <Helmet>
        {ENV === 'UAT' && (<meta name="robots" content="noindex, nofollow" />)}
      </Helmet>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route exact path={route.home} element={<Home />} />
          <Route exact path={route.card} element={<PrepaidCard />} />
          <Route exact path={route.us} element={<Us />} />
          <Route exact path={route.physicalCommerce} element={<PhysicalCommerce />} />
          <Route exact path={route.buyNowPayLater} element={<BuyNowPayLater />} />
          <Route exact path={route.help} element={<Help />} />
          <Route exact path={route.wt} element={<Wt />} />
          <Route exact path={route.blog} element={<Blog />} />
          <Route path={`${route.blog}/:keyWords/:idNote`} element={<BlogNote />} />
          <Route path={`${route.oldNotes}/:idNote/:keyWords`} element={<BlogNote />} />
          <Route path={route.news} element={<Navigate to={route.blog} replace />} />
          <Route exact path={route.jobs} element={<Jobs />} />
          <Route exact path={route.investors} element={<Investors />} />
          <Route exact path={route.financeUserInformation} element={<FinanceUserInformation />} />
          <Route exact path={route.consumerProtection} element={<ConsumerProtection />} />
          <Route exact path={route.cancelCredit} element={<CancelCredit />} />
          <Route exact path={route.branch} element={<Branches />} />
          <Route exact path={route.whatsapp} element={<Whatsapp />} />
          <Route exact path={route.uber} element={<Uber />} />
          <Route exact path={route.recharge} element={<Recharge />} />
          <Route exact path={route.services} element={<Services />} />
          <Route exact path={route.cellphoneRecharge} element={<CellphoneRecharge />} />
          <Route exact path={route.unsubscribeInformation} element={<UnsubscribeInformation />} />
          <Route exact path={route.payServices} element={<PayServices />} />
          <Route exact path={route.hubLoyal} element={<HubRenewers />} />
          <Route exact path={route.hubCustomers} element={<HubRenewers loyalCustomer={false} />} />
          <Route exact path={route.contracts} element={<Contracts />} />
          <Route exact path={route.motorcycle} element={<Motorcycle />} />
          <Route exact path={route.electro} element={<LandingElectro />} />
          <Route exact path={route.refurbish} element={<LandingRefurbish />} />
        </Routes>
      </ThemeProvider>
    </HelmetProvider>
    <footer>
      <Footer />
      {(window.location.pathname === route.home || window.location.pathname === route.card) && (
        <LegalFooter boxStyle={{ backgroundColor: '#E2E2E2', color: '#4a4a4a', marginTop: '0' }} />
      )}
      <FooterLogos />
    </footer>
  </BrowserRouter>
);

export default AppRouter;
