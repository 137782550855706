import { useState, useEffect } from 'react';
import {
  useMediaQuery, Grid,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import SideBarMenu from './SideBarMenu';
import logoCredicuotas from '../../images/logoCredicuotas.png';
import i18n from '../common/i18n';
import NavBar from './NavBar';
import '../../assests/css/headerStyle.css';
import { route } from '../navigation/routes';
import {
  ENVIRONMENT, getEnv,
} from '../../api/env';

const analytics = () => {
  const location = useLocation().pathname;

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        path: location,
      },
    });
    ReactGA.pageview(location);
    ReactPixel.pageView(location);
  }, [location]);
};

const Header = () => {
  const matchesMobile = useMediaQuery('(max-width: 1264px)');
  const [hide, setHide] = useState(false);
  const location = useLocation();
  const pathToExcludeHeader = [
    route.whatsapp.toLowerCase(),
    route.uber.toLowerCase(),
    route.branch.toLowerCase(),
    route.recharge.toLowerCase(),
    route.services.toLowerCase(),
    route.cellphoneRecharge.toLowerCase(),
  ];
  const ENV = getEnv(ENVIRONMENT);
  if (ENV === 'UAT' || ENV === 'PRD') {
    analytics();
  }
  const init = () => {
    const hideHeader = pathToExcludeHeader.includes(location.pathname.toLowerCase());
    setHide(hideHeader);
  };

  useEffect(() => init(), []);

  return (
    <div className="header">
      {!hide && (
        <header style={{ backgroundColor: 'white', height: '80px' }}>
          <Grid container>
            <Grid item xs={10} md={2} className="gridLogoHeader">
              <Link
                to={{ pathname: '/' }}
                style={{ textDecoration: 'none' }}
              >
                <img
                  src={logoCredicuotas}
                  alt={i18n.credicuotas}
                  className="logoHeader"
                />
              </Link>
            </Grid>
            {
              matchesMobile ? (
                <>
                  <Grid item md={8} />
                  <Grid item xs={2} md={2}>
                    <nav className="nav-area menuHamburguesa">
                      <SideBarMenu />
                    </nav>
                  </Grid>
                </>
              ) : (
                <Grid item md={10}>
                  <nav className="nav-area">
                    <NavBar />
                  </nav>
                </Grid>
              )
            }
          </Grid>
        </header>
      )}
    </div>
  );
};

export default Header;
