import axios from 'axios';
import soapZendesk from './soapZendesk';
import {
  getEnv, ZENDESK,
} from './env';

const serviceZendesk = async (data) => {
  const envZendesk = getEnv(ZENDESK);
  const zendeskXml = soapZendesk(data);
  let validator;
  await axios
    .post(
      envZendesk,
      zendeskXml,
      {
        headers: { 'Content-Type': 'text/xml' },
      },
    )
    .then((response) => {
      if (response.status === 200 && response.data) {
        validator = response.data;
      }
    })
    .catch(() => {
      validator = false;
    });
  return validator;
};

export default serviceZendesk;
