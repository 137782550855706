import {
  Button, Box, Grid, Typography, useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import i18n from '../common/i18n';
import bannerImage from '../../assests/images/home/bannerHome.svg';
import iconBannerWc from '../../assests/images/error/error404.png';
import routes from '../navigation/routes';
import '../../assests/css/errorStyle.css';

const NotFound = () => {
  const matchesMobile = useMediaQuery('(max-width: 1201px)');
  return (
    <>
      <Helmet>
        <title>{i18n.errorTitle}</title>
        <meta name="description" content={i18n.errorTitle} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {
      matchesMobile ? (
        <Box mt={10}>
          <Box className="mainBox flexCenter" style={{ backgroundImage: `url(${bannerImage})` }}>
            <Box className="boxTitlesBanner">
              <Typography variant="h1" fontWeight={700} className="lettersWhiteOnly">{i18n.errorTitle}</Typography>
              <Box className="spaceBetween widthBoxTypography">
                <Typography variant="h6" fontSize={24} className="lettersWhiteOnly">{i18n.errorSubtitle}</Typography>
              </Box>
              <Box className="boxButtonError">
                <Button variant="contained" className="ButtonMagenta buttonMoreInfo buttonError">
                  {i18n.errorButton}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box mt={11}>
          <Box className="mainBoxError flexCenter" style={{ backgroundImage: `url(${bannerImage})` }}>
            <Grid container>
              <Grid item lg={1.2} />
              <Grid item lg={6} className="gridBannerTitle" mt={4}>
                <Typography variant="h1" fontWeight={700} className="lettersWhiteOnly">{i18n.errorTitle}</Typography>
                <Box className="boxSubtitle">
                  <Typography variant="h5" className="lettersWhiteOnly">{i18n.errorSubtitle}</Typography>
                </Box>
                <Link to={routes.home} style={{ textDecoration: 'none' }}>
                  <Button variant="contained" className="ButtonMagenta buttonMoreInfo buttonError">
                    {i18n.errorButton}
                  </Button>
                </Link>
              </Grid>
              <Grid item lg={3.8} className="flexCenter">
                <img src={iconBannerWc} alt="icon" className="errorImage" />
              </Grid>
              <Grid item lg={1} />
            </Grid>
          </Box>
        </Box>
      )
    }
    </>
  );
};

export default NotFound;
