import markUpSchema from '../constants/markUpSchema';

const generateArticleSchemaOrStaticSchema = (blogNoteData) => {
  if (blogNoteData && blogNoteData.url) {
    const authorSchemaType = blogNoteData.authorType ? 'Organization' : 'Person';
    const authorName = blogNoteData.authorName || 'Jane Doe';
    return {
      '@context': 'https://schema.org',
      '@type': 'Article',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': blogNoteData.url,
      },
      headline: blogNoteData.headline || '',
      description: blogNoteData.description || '',
      image: blogNoteData.urlImage,
      author: {
        '@type': authorSchemaType,
        name: authorName,
      },
      publisher: {
        '@type': 'Organization',
        name: 'CrediCuotas',
        logo: {
          '@type': 'ImageObject',
          url: blogNoteData.urlImage,
        },
      },
      datePublished: blogNoteData.datePublished || '',
    };
  }
  return markUpSchema;
};

export default generateArticleSchemaOrStaticSchema;
