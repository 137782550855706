import {
  Box, Button, Card, CardContent, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import '../assests/css/pressCardStyle.css';

const PressCard = ({
  title, description, footer, redirectLink, redirectLabel,
}) => {
  const redirect = () => {
    window.open(redirectLink, '_blank');
  };

  return (
    <Card variant="outlined" className="press-card-container">
      <CardContent>
        <Typography className="press-card-title">{title}</Typography>
        <Box mt={2} />
        <Typography>{description}</Typography>
        <Box mt={3} className="press-card-footer-box">
          <Typography className="press-card-footer">{footer}</Typography>
          <Button onClick={redirect} variant="outlined" className="press-card-btn">{redirectLabel}</Button>
        </Box>
      </CardContent>
    </Card>
  );
};

PressCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
  redirectLink: PropTypes.string.isRequired,
  redirectLabel: PropTypes.string.isRequired,
};

export default PressCard;
