import './App.css';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import AppRouter from './components/navigation/AppRouter';
import {
  getEnv, ENVIRONMENT, GOOGLE_TAG_MANAGER_ID, GOOGLE_ANALYTICS_ID,
} from './api/env';

function App() {
  const ENV = getEnv(ENVIRONMENT);

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  const advancedMatching = {};

  if (ENV === 'UAT' || ENV === 'PRD') {
    TagManager.initialize({ gtmId: getEnv(GOOGLE_TAG_MANAGER_ID) });
    ReactGA.initialize(getEnv(GOOGLE_ANALYTICS_ID));
    ReactPixel.init('339229032950039', advancedMatching, options);
  }
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
