import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { getEnv, ENVIRONMENT } from '../../api/env';
import generateArticleSchemaOrStaticSchema from '../../utils/generateArticleSchemaOrStaticSchema';

const HeaderTags = ({
  title, content, metaContent, scriptMarkUpSchema, blogNoteData,
}) => {
  const ENV = getEnv(ENVIRONMENT);

  useEffect(() => {
    const scriptId = 'scriptMarkUpSchema';
    const existingScript = document.getElementById(scriptId);

    const schema = generateArticleSchemaOrStaticSchema(blogNoteData);

    if (scriptMarkUpSchema && !existingScript) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.type = 'application/ld+json';
      script.textContent = JSON.stringify(schema);
      document.head.appendChild(script);
    }

    return () => {
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [scriptMarkUpSchema, blogNoteData]);

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={window.location.href} />
      <meta name="description" content={content} />
      {ENV !== 'UAT' && (<meta name="robots" content={metaContent} />)}
    </Helmet>
  );
};

HeaderTags.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  metaContent: PropTypes.string,
  scriptMarkUpSchema: PropTypes.bool,
  blogNoteData: PropTypes.shape({
    url: PropTypes.string,
    headline: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    authorName: PropTypes.string,
    datePublished: PropTypes.string,
  }),
};

HeaderTags.defaultProps = {
  metaContent: 'index, follow',
  scriptMarkUpSchema: false,
  blogNoteData: null,
};

export default HeaderTags;
