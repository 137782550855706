import PropTypes from 'prop-types';
import {
  Box, Grid, Typography, useMediaQuery,
} from '@mui/material';
import parse from 'html-react-parser';
import bannerImg from '../../assests/images/hubRenewers/chica-saltando 1.png';
import personalLoanImg from '../../assests/images/hubRenewers/personal_loan_icon.png';
import servicesPaymentImg from '../../assests/images/hubRenewers/services_payment_icon.png';
import rechargeImg from '../../assests/images/hubRenewers/recharge_icon.png';
import personalLoanOnboardingImg from '../../assests/images/hubRenewers/onboarding_1.png';
import servicesOnboardingImg from '../../assests/images/hubRenewers/onboarding_2.png';
import rechargeOnboardingImg from '../../assests/images/hubRenewers/onboarding_3.png';
import '../../assests/css/hubRenewersStyle.css';
import i18n from '../common/i18n';
import OnboardingCard from '../OnboardingCard';
import {
  PERSONAL_LOAN_URL, RECHARGE_URL, SERVICE_URL, getEnv,
} from '../../api/env';
import BenefitCard from '../BenefitCard';
import { getBenefitList } from '../../constants/hubRenewerConstants';
import HeaderTags from '../common/HeaderTags';

const HubRenewers = ({ loyalCustomer }) => {
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const goToPersonalLoan = () => {
    window.open(getEnv(PERSONAL_LOAN_URL), '_blank');
  };

  const goToServicePayment = () => {
    window.open(getEnv(SERVICE_URL), '_blank');
  };

  const goToRecharge = () => {
    window.open(getEnv(RECHARGE_URL), '_blank');
  };

  return (
    <>
      <HeaderTags
        title={loyalCustomer ? i18n.HubRenewers.renewerCustomerTitle : i18n.HubRenewers.loyalCustomerTitle}
        content={loyalCustomer ? i18n.HubRenewers.renewerCustomerTitle : i18n.HubRenewers.loyalCustomerTitle}
        metaContent="noindex, nofollow"
      />
      {/* ********** BANNER ********** */}
      <Box mt={10} className="hub-renewer-banner">
        <Box>
          <Typography className="hub-renewer-banner-title">{i18n.HubRenewers.bannerTitle}</Typography>
          <Typography className="hub-renewer-banner-description">
            {parse(i18n.HubRenewers.bannerDescription)}
          </Typography>
        </Box>
        {!isMobile && (
          <img src={bannerImg} alt="LOGO" />
        )}
      </Box>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          {/* ********** COMO CONTINUAR ********** */}
          <Typography className="hub-renewer-section-title">{i18n.HubRenewers.howContinue}</Typography>
          <Box mt={5} />
          <OnboardingCard
            title={i18n.HubRenewers.onboardingCard[0].title}
            description={i18n.HubRenewers.onboardingCard[0].description}
            ctaLabel={i18n.HubRenewers.onboardingCard[0].ctaLabel}
            imgDesktop={personalLoanOnboardingImg}
            imgMobile={personalLoanImg}
            onClick={goToPersonalLoan}
          />
          <Box mt={3} />
          <OnboardingCard
            title={i18n.HubRenewers.onboardingCard[1].title}
            description={i18n.HubRenewers.onboardingCard[1].description}
            ctaLabel={i18n.HubRenewers.onboardingCard[1].ctaLabel}
            imgDesktop={servicesOnboardingImg}
            imgMobile={servicesPaymentImg}
            onClick={goToServicePayment}
          />
          <Box mt={3} />
          <OnboardingCard
            title={i18n.HubRenewers.onboardingCard[2].title}
            description={i18n.HubRenewers.onboardingCard[2].description}
            ctaLabel={i18n.HubRenewers.onboardingCard[2].ctaLabel}
            imgDesktop={rechargeOnboardingImg}
            imgMobile={rechargeImg}
            onClick={goToRecharge}
          />
          {/* ********** BENEFICIOS ********** */}
          <Typography className="hub-renewer-section-title">{i18n.HubRenewers.benefit}</Typography>
          <Box mt={5} />
          <Grid container spacing={{ xs: 2, sm: 2, md: 3 }}>
            {getBenefitList(loyalCustomer).map((benefit) => (
              <Grid item xs={6} sm={6} md={loyalCustomer ? 4 : 6} key={benefit.description}>
                <BenefitCard img={benefit.img} description={benefit.description} />
              </Grid>
            ))}
          </Grid>
          {/* ********** BANNER FINAL ********** */}
          <Box mt={5} />
          <Box className="hub-renewer-final-banner-container">
            <Typography className="hub-renewer-final-banner-title">{i18n.HubRenewers.finalBannerTitle}</Typography>
            <Typography className="hub-renewer-final-banner-description">
              {i18n.HubRenewers.finalBannerDescription}
            </Typography>
          </Box>
          <Box mt={5} />
        </Grid>
      </Grid>
    </>
  );
};

HubRenewers.propTypes = {
  loyalCustomer: PropTypes.bool,
};

HubRenewers.defaultProps = {
  loyalCustomer: true,
};

export default HubRenewers;
