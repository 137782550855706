import Axios from 'axios';
import { SELFIE_WEB, getEnv } from './env';

const urlBackend = getEnv(SELFIE_WEB);

export const instance = Axios.create({
  timeout: 50000,
  baseURL: `${urlBackend}`,
  headers: { 'content-type': 'application/json;charset=UTF-8' },
});

export const fetch = (config) => instance.request(config)
  .then((res) => res)
  .catch((err) => {
    throw err;
  });

export default fetch;
