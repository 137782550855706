/* eslint-disable react/no-danger */
import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  Grid, Box, Button, Typography,
} from '@mui/material';
import { getSpecificNote, getOtherNotes, getFeaturedNotes } from '../../api/serviceBlog';
import { BLOG, getEnv } from '../../api/env';
import i18n from '../common/i18n';
import RowNotes from './RowNotes';
import { route } from '../navigation/routes';
import HeaderTags from '../common/HeaderTags';
import Breadcrumbs from './BlogBreadcumbs';
import '../../assests/css/styledBreadcrumbs.css';

const urlBackend = getEnv(BLOG);

const BlogNote = () => {
  const { idNote } = useParams();
  const [note, setNote] = useState({
    title: '', preview_text: '', publish_text: '', content: '',
  });
  const [noteContent, setNoteContent] = useState(false);
  const [urlImage, setUrlImage] = useState(undefined);
  const [relationatedNotes, setRelationatedNotes] = useState([{}]);
  const urlLocation = window.location.href;
  const navigate = useNavigate();
  // chequea si es la url de la web anterior y redirecciona a la url de la misma nota en la nueva web
  const checkOldBlogUrlAndRedirect = (title) => {
    if (window.location.href.indexOf('novedades/notas/') > 1) {
      const keyWords = title
        .replace(/¿/g, '')
        .replace(/\?/g, '')
        .replace(/:/g, ' ')
        .replace(/\s+/g, '-')
        .toLowerCase();
      navigate(`${route.blog}/${keyWords}-${idNote}`);
    }
  };

  const getNotePreview = async () => {
    const fNotes = await getFeaturedNotes();
    let notePreview = fNotes.filter((n) => String(n.note_id) === idNote);
    if (notePreview.length === 0) {
      const notes = await getOtherNotes(Number(idNote) + 1);
      notePreview = notes.filter((n) => String(n.note_id) === idNote);
    }
    return notePreview[0]?.text;
  };

  const getNote = async () => {
    const specificNote = await getSpecificNote(idNote);
    specificNote.preview_text = await getNotePreview();
    checkOldBlogUrlAndRedirect(specificNote.title);
    setNote(specificNote);
    setUrlImage(`${urlBackend}/${specificNote.image}`);
    setNoteContent(true);
  };

  const getRelationatedNotes = async () => {
    const notes = await getOtherNotes(0);
    const lastNotes = notes.filter((n) => String(n.note_id) !== idNote).slice(0, 3);
    setRelationatedNotes(lastNotes);
  };

  function refreshContent() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getNote();
    getRelationatedNotes();
  }

  useEffect(() => {
    refreshContent();
  }, []);

  useEffect(() => {
    if (noteContent) {
      refreshContent();
    }
  }, [urlLocation]);

  return (
    <>
      <HeaderTags
        title={note.title}
        content={note.content}
        scriptMarkUpSchema
        blogNoteData={{
          url: urlLocation,
          headline: note.tag,
          description: note.preview_text,
          urlImage: `${urlBackend}/${note.image}`,
          datePublished: note.created_at,
        }}
      />

      <Grid container mt={10}>
        <Grid item xs={12} sx={{ position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '180px',
              marginTop: '37px',
            }}
          >
            <Breadcrumbs className="breadcrumbs" />
          </Box>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Box
            sx={{
              marginTop: '5%', border: 1, borderRadius: '16px', borderColor: '#BFBFBF',
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h4" fontWeight={800} component="h1" className="lettersGrey">{note.title}</Typography>
            <Typography variant="h6" component="h2" className="lettersGrey">{note.publish_text}</Typography>
            <img src={urlImage} alt={note.title} width="90%" />
            <div className="content" dangerouslySetInnerHTML={{ __html: note.content }} />
          </Box>
          <Box className="centerTitle">
            <Typography mt={8} mb={4} variant="h4" fontWeight={700} className="lettersGrey">
              {i18n.communityRelated}
            </Typography>
          </Box>
          <RowNotes notes={relationatedNotes} />
          <Link
            to={{ pathname: route.blog }}
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="contained"
              className="ButtonMagenta"
              sx={{ marginTop: '1%', marginBottom: '3%' }}
            >
              {i18n.blogNoteComeBack}
            </Button>
          </Link>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

export default BlogNote;
