import { Grid, Box, Typography } from '@mui/material';
// eslint-disable-next-line import/no-named-as-default
import i18n from '../common/i18n';
import Banner from '../common/Banner';
import bannerImage from '../../assests/images/home/bannerHome.svg';
import notifications from '../../assests/images/consumerProtection/notificaciones.svg';
import '../../assests/css/consumerProtectionStyle.css';
import HeaderTags from '../common/HeaderTags';

const ConsumerProtection = () => (
  <>
    <HeaderTags
      title={i18n.consumerTitle}
      content={i18n.metaDescriptionConsumerProtection}
      metaContent="noindex, follow"
    />
    {/* banner */}
    <Box mt={10}>
      <Banner title={i18n.consumerTitle} backgroundImage={bannerImage} />
    </Box>
    <Box className="flexCenter">
      <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxConsumer" mt={8}>
        {i18n.consumerAskHere}
      </Typography>
    </Box>
    <Grid container className="flexCenter" mt={5}>
      <Grid item xs={2} md={2} lg={2} />
      <Grid item xs={8} lg={8} className="flexCenter">
        <Grid container className="flexCenter">
          <Grid item xs={12} lg={4} className="flexCenter">
            <a
              href="https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario"
              rel="noopener noreferrer nofollow"
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Box className="boxForWhatConsumer flexCenter" backgroundColor="#FFC761">
                <Box className="boxTypographyConsumer">
                  <Typography variant="h6" fontWeight={400} className="lettersGrey">
                    {i18n.consumerFirstP}
                  </Typography>
                </Box>
              </Box>
            </a>
          </Grid>
          <Grid item xs={12} lg={4} className="flexCenter marginUp">
            <a
              href="https://www.buenosaires.gob.ar/defensaconsumidor/como-denunciar"
              rel="noopener noreferrer nofollow"
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Box className="boxForWhatConsumer flexCenter" backgroundColor="#EFBDDB">
                <Box className="boxTypographyConsumer">
                  <Typography variant="h6" fontWeight={400} className="lettersGrey">
                    {i18n.consumerSecondP}
                  </Typography>
                </Box>
              </Box>
            </a>
          </Grid>
          <Grid item xs={12} lg={4} className="flexCenter marginUp">
            <a
              href="https://www.argentina.gob.ar/justicia/derechofacil/leysimple/defensa-del-consumidor"
              rel="noopener noreferrer nofollow"
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Box className="boxForWhatConsumer flexCenter" backgroundColor="#F9CFD4">
                <Box className="boxTypographyConsumer">
                  <Typography variant="h6" fontWeight={400} className="lettersGrey">
                    {i18n.consumerThirdP}
                  </Typography>
                </Box>
              </Box>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} md={2} lg={2} />
    </Grid>
    <Box mt={10} className="boxLegalNotifications">
      <Grid container>
        <Grid item xs={1} lg={0.1} />
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12} lg={6} className="flexCenter">
              <Box className="marginNotifications">
                <img src={notifications} alt="notifications" className="imgNotifications" />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} className="flexCenter marginUp">
              <Box className="textLeft">
                <Box className="widthLess">
                  <Typography variant="h2" fontWeight={800} className="lettersGrey">
                    {i18n.consumerNotifications}
                  </Typography>
                </Box>
                <Box mt={2.5}>
                  <Typography variant="p" fontSize={18}>
                    {i18n.consumerLegalInfo}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="p" fontSize={18}>
                    {i18n.consumerAudience}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="p" fontSize={18}>
                    {i18n.consumerJudge}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="p" fontSize={18}>
                    {i18n.consumerOmic}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="p" fontSize={18}>
                    {i18n.consumerCoprec}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="p" fontSize={18}>
                    {i18n.consumerTribunal}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} lg={1.9} />
      </Grid>
    </Box>
  </>
);

export default ConsumerProtection;
