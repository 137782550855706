import {
  Grid, Box, Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet-async';
import i18n from '../common/i18n';
import WtCalculator from '../calculator/WtCalculator';
import Banner from '../common/Banner';
import bannerImage from '../../assests/images/home/bannerHome.svg';
import selfieRequirement from '../../assests/images/home/recurso-home-19.png';
import moneyRequirement from '../../assests/images/home/recurso-home-17.png';
import documentRequirement from '../../assests/images/home/recurso-home-15.png';
import phoneRequirement from '../../assests/images/home/recurso-home-16.png';
import '../../assests/css/wtStyle.css';
import {
  ORIGINACION_WT, getEnv,
} from '../../api/env';

const Wt = () => {
  const ORIGINACION_WT_LINK = getEnv(ORIGINACION_WT);
  return (
    <>
      <Helmet>
        <title>{i18n.footerPersonalLoans}</title>
        <meta name="description" content={i18n.footerPersonalLoans} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {/* banner */}
      <Banner title={i18n.footerPersonalLoans} subtitle={i18n.wtSubtitle} backgroundImage={bannerImage} />
      {/* calculadora */}
      <Box className="boxCalculator">
        <WtCalculator />
      </Box>
      {/* como funciona */}
      <Grid container className="gridSpaces">
        <Grid item xs={1} />
        <Grid item xs={10} md={12}>
          <Typography variant="h4" component="h2" className="gridSlider">{i18n.wtHowWorks}</Typography>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <Box className="requirementsBox">
                <img src={documentRequirement} alt="documentRequirement" width="300px" />
              </Box>
              <Box className="pBoxHome pBoxHomeRequirements">
                <Typography variant="h6" component="h3">{i18n.homeRequirementAdult}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box className="requirementsBox">
                <img src={selfieRequirement} alt="selfieRequirement" width="220px" />
              </Box>
              <Box className="pBoxHome pBoxHomeRequirements">
                <Typography variant="h6" component="h3">{i18n.homeRequirementTelephone}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box className="requirementsBox">
                <img src={moneyRequirement} alt="moneyRequirement" width="210px" />
              </Box>
              <Box className="pBoxHome pBoxHomeRequirements">
                <Typography variant="h6" component="h3">{i18n.homeRequirementWhite}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {/* como lo hago */}
      <Grid container className="gridSpaces">
        <Grid item xs={1} />
        <Grid item xs={10} md={12}>
          <Typography variant="h4" component="h2" className="gridSlider">{i18n.wtRequiriments}</Typography>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <Box className="requirementsBox">
                <img src={documentRequirement} alt="documentRequirement" width="300px" />
              </Box>
              <Box className="pBoxHome pBoxHomeRequirements">
                <Typography variant="h6" component="h3">{i18n.homeEnterYourData}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box className="requirementsBox">
                <img src={phoneRequirement} alt="phoneRequirement" width="180px" />
              </Box>
              <Box className="pBoxHome pBoxHomeRequirements">
                <Typography variant="h6" component="h3">{i18n.homeValidateIdentity}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box className="requirementsBox">
                <img src={moneyRequirement} alt="moneyRequirement" width="210px" />
              </Box>
              <Box className="pBoxHome pBoxHomeRequirements">
                <Typography variant="h6" component="h3">{i18n.homeReceive}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {/* preguntas o datos básicos */}
      <Grid container className="gridSpaces">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Box className="marginBottom">
            <Typography variant="h4" component="h2" className="gridSlider">{i18n.faq}</Typography>
          </Box>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" component="h4" className="alignLeft">{i18n.wtFirstQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="alignLeft">
              <Typography variant="p">
                {i18n.wtFirstAnswerOne}
                <a
                  href={ORIGINACION_WT_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {i18n.wtFirstAnswerCTA}
                </a>
                {i18n.wtFirstAnswerTwo}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" component="h4" className="alignLeft">{i18n.wtSecondQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="alignLeft">
              <Typography variant="p">
                {i18n.wtSecondAnswerOne}
                <a
                  href={ORIGINACION_WT_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {i18n.homeAnswerCTAOriginationHere}
                </a>
                {i18n.wtSecondAnswerTwo}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" component="h4" className="alignLeft">{i18n.wtThirdQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="alignLeft">
              <Typography variant="p">
                {i18n.wtThirdAnswer}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" component="h4" className="alignLeft">{i18n.wtFourthQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="alignLeft">
              <Typography variant="p">
                {i18n.wtFourthAnswer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

export default Wt;
