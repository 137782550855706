import { fetch } from './axiosInstance';

export const getCalculatorData = () =>
  fetch({
    url: '/v1/onboarding/simulatormatrix/56',
    method: 'get',
  });

export const getStates = () =>
  fetch({
    url: '/v1/workflow/datasource/provinces',
    method: 'get',
  });

export const getFooterData = (landing) =>
  fetch({
    url: `/v1/webParameters/footerData/${landing}`,
    method: 'get',
  });
