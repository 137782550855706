import imgLamp from '../assests/images/payServices/lamparita.svg';
import imgWater from '../assests/images/payServices/agua.svg';
import imgGas from '../assests/images/payServices/gas.svg';
import imgCellphone from '../assests/images/payServices/cellphone.svg';
import imgTv from '../assests/images/payServices/tv.svg';
import imgHealth from '../assests/images/payServices/prepaga.svg';
import imgInsurance from '../assests/images/payServices/seguro.svg';
import imgInternet from '../assests/images/payServices/internet.svg';
import i18n from '../components/common/i18n';

export const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        infinite: true,
        speed: 500,
      },
    },
  ],
};

export const paymentServiceList = [
  {
    classNameFirstBox: 'payServicesWhatToPayFlexBoxCircle',
    classNameSecondBox: 'sliderBoxCircle payServicesWhatToPayFlexBoxCircle',
    img: imgLamp,
    classNameImg: 'sliderImgBoxCircle',
    classNameTypography: 'sliderBoxTypography',
    text: i18n.payServicesLight,
  },
  {
    classNameFirstBox: 'payServicesWhatToPayFlexBoxCircle',
    classNameSecondBox: 'sliderBoxCircle payServicesWhatToPayFlexBoxCircleWater',
    img: imgWater,
    classNameImg: 'sliderImgBoxCircleWater',
    classNameTypography: 'sliderBoxTypography sliderBoxTypographyWater',
    text: i18n.payServicesWater,
  },
  {
    classNameFirstBox: 'payServicesWhatToPayFlexBoxCircle',
    classNameSecondBox: 'sliderBoxCircle payServicesWhatToPayFlexBoxCircleGas',
    img: imgGas,
    classNameImg: 'sliderImgBoxCircleInsurance',
    classNameTypography: 'sliderBoxTypography',
    text: i18n.payServicesGas,
  },
  {
    classNameFirstBox: 'payServicesWhatToPayFlexBoxCircle',
    classNameSecondBox: 'sliderBoxCircle payServicesWhatToPayFlexBoxCircle',
    img: imgCellphone,
    classNameImg: 'sliderImgBoxCircleRecharge',
    classNameTypography: 'sliderBoxTypography sliderBoxTypographyRecharge',
    text: i18n.payServicesCellphone,
  },
  {
    classNameFirstBox: 'payServicesWhatToPayFlexBoxCircle',
    classNameSecondBox: 'sliderBoxCircle payServicesWhatToPayFlexBoxCircle',
    img: imgTv,
    classNameImg: 'sliderImgBoxCircleInsurance',
    classNameTypography: 'sliderBoxTypography',
    text: i18n.payServicesTv,
  },
  {
    classNameFirstBox: 'payServicesWhatToPayFlexBoxCircle',
    classNameSecondBox: 'sliderBoxCircle payServicesWhatToPayFlexBoxCircle',
    img: imgHealth,
    classNameImg: 'sliderImgBoxCircleInsurance',
    classNameTypography: 'sliderBoxTypography',
    text: i18n.payServicesHealth,
  },
  {
    classNameFirstBox: 'payServicesWhatToPayFlexBoxCircle',
    classNameSecondBox: 'sliderBoxCircle payServicesWhatToPayFlexBoxCircle',
    img: imgInsurance,
    classNameImg: 'sliderImgBoxCircleInsurance',
    classNameTypography: 'sliderBoxTypography',
    text: i18n.payServicesInsurance,
  },
  {
    classNameFirstBox: 'payServicesWhatToPayFlexBoxCircle',
    classNameSecondBox: 'sliderBoxCircle payServicesWhatToPayFlexBoxCircle',
    img: imgInternet,
    classNameImg: 'sliderImgBoxCircleInsurance',
    classNameTypography: 'sliderBoxTypography',
    text: i18n.payServicesInternet,
  },
];
