import PropTypes from 'prop-types';
import {
  Box, Card, CardContent, Typography,
} from '@mui/material';
import '../assests/css/benefitCardStyle.css';

const BenefitCard = ({ img, description }) => (
  <Card>
    <CardContent>
      <Box className="benefit-card-container">
        <img src={img} alt="LOGO" className="benefit-card-img" />
        <Typography className="benefit-card-description">{description}</Typography>
      </Box>
    </CardContent>
  </Card>
);

BenefitCard.propTypes = {
  img: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default BenefitCard;
