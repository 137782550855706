import benefit1 from '../assests/images/hubRenewers/benefit_1.png';
import benefit2 from '../assests/images/hubRenewers/benefit_2.png';
import benefit3 from '../assests/images/hubRenewers/benefit_3.png';
import benefit4 from '../assests/images/hubRenewers/benefit_4.png';
import benefit5 from '../assests/images/hubRenewers/benefit_5.png';
import benefit6 from '../assests/images/hubRenewers/benefit_6.png';
import i18n from '../components/common/i18n';

export const loyalBenefitList = [
  { img: benefit1, description: i18n.HubRenewers.benefitDescription[0] },
  { img: benefit2, description: i18n.HubRenewers.benefitDescription[1] },
  { img: benefit3, description: i18n.HubRenewers.benefitDescription[2] },
  { img: benefit4, description: i18n.HubRenewers.benefitDescription[3] },
  { img: benefit5, description: i18n.HubRenewers.benefitDescription[4] },
  { img: benefit6, description: i18n.HubRenewers.benefitDescription[5] },
];

export const customerBenefitList = [
  { img: benefit1, description: i18n.HubRenewers.benefitDescription[0] },
  { img: benefit3, description: i18n.HubRenewers.benefitDescription[2] },
  { img: benefit4, description: i18n.HubRenewers.benefitDescription[3] },
  { img: benefit5, description: i18n.HubRenewers.benefitDescription[4] },
];

export const getBenefitList = (isLoyalCustomer) => {
  let benefitList = customerBenefitList;
  if (isLoyalCustomer) {
    benefitList = loyalBenefitList;
  }
  return benefitList;
};
