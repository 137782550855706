import i18n from '../components/common/i18n';

const fillRegexp = /\$\{([^\\}]*(?:\\.[^\\}]*)*)\}/g;

export const fillMessageWith = (message, ...args) => {
  const values = args.reduce((acc, v, i) => {
    acc[i.toString()] = v;
    return acc;
  }, {});
  return message.replace(fillRegexp, (exp, prop) => (values[prop] !== undefined ? values[prop] : ''));
};

export const translateErrorOrDefault = (errorCode, defaultMessage) => {
  const errorMessage = errorCode ? i18n[errorCode] : undefined;
  return errorMessage !== undefined ? errorMessage
    : (defaultMessage !== undefined ? defaultMessage
      : 'Ocurrio un error!');
};

export const generateLinkWithFileInResponse = (res) => {
  const blob = new Blob([res], { type: 'application/pdf', encoding: 'UTF-8' });
  const link = document.createElement('a');
  link.setAttribute('href', window.URL.createObjectURL(blob));
  link.setAttribute('target', '_blank');
  link.click();
};

export const isMobileOperatingSystem = () => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.window.opera;
  if (/windows phone/i.test(userAgent)) {
    return true;
  }
  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};
