import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18n from './common/i18n';

const LandingFaqs = ({ faqs }) => (
  <>
    <Box mt={5} mb={5}>
      <Typography variant="h2">{i18n.faqTitle}</Typography>
    </Box>
    <Box pl={{ md: 10, xs: 2 }} pr={{ md: 10, xs: 2 }}>
      {faqs.map((faq) => (
        <Accordion key={faq.question} className="marginBottomQuestions">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="textLeft" variant="h5">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails className="textLeft">
            <Typography>{parse(faq.answer)}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
    <Box mt={5} mb={5}>
      <Typography className="landing-faq-more">
        {i18n.faqMore[0]}
        <a href="https://www.credicuotas.com.ar/ayuda">{i18n.faqMore[1]}</a>
      </Typography>
    </Box>
  </>
);

LandingFaqs.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default LandingFaqs;
