import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { isMobileOperatingSystem } from '../utils/functionUtils';

const LandingRequirements = ({ title, requirements }) => (
  <Box className="landing-requirements">
    <Grid container pl={isMobileOperatingSystem() ? 0 : 2} pr={isMobileOperatingSystem() ? 0 : 2}>
      <Grid item xs={12} md={3}>
        <Typography variant="h2">{title}</Typography>
      </Grid>
      {requirements.map((req) => (
        <Grid item xs={12} md={3} key={req.title} mt={isMobileOperatingSystem() ? 3 : 0}>
          <img src={isMobileOperatingSystem() ? req.image[1] : req.image[0]} alt="REQUISITO" />
          <Typography>{req.description}</Typography>
        </Grid>
      ))}
    </Grid>
  </Box>
);

LandingRequirements.propTypes = {
  title: PropTypes.string.isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default LandingRequirements;
