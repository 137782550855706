import {
  Box, Card, CardContent, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import '../assests/css/historyCardStyle.css';

const HistoryCard = ({ historyData }) => (
  <Card variant="outlined" className="history-card-container">
    <CardContent>
      <Typography className="history-card-title">{parse(historyData.title)}</Typography>
      <Box mt={2} />
      <Typography>{parse(historyData.description)}</Typography>
      {(historyData.img1 || historyData.img2) && (
        <Box className="history-card-img-box">
          {historyData.img1 && (
            <img src={historyData.img1} alt="history_img" className="history-card-img1" />
          )}
          {historyData.img2 && (
            <img src={historyData.img2} alt="history_img" className="history-card-img2" />
          )}
        </Box>
      )}
    </CardContent>
  </Card>
);

HistoryCard.propTypes = {
  historyData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    img1: PropTypes.node,
    img2: PropTypes.node,
  }).isRequired,
};

export default HistoryCard;
