import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const PayServiceSliderCard = ({ service }) => (
  <Box key={service.text}>
    <Box className={service.classNameFirstBox}>
      <Box className={service.classNameSecondBox}>
        <img src={service.img} alt={service.text} className={service.classNameImg} />
        <Box className={service.classNameTypography}>
          <Typography variant="h5" fontWeight={500} fontSize={14}>
            {service.text}
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
);

PayServiceSliderCard.propTypes = {
  service: PropTypes.shape({
    classNameFirstBox: PropTypes.string,
    classNameSecondBox: PropTypes.string,
    img: PropTypes.string,
    text: PropTypes.string,
    classNameImg: PropTypes.string,
    classNameTypography: PropTypes.string,
  }).isRequired,
};

export default PayServiceSliderCard;
