import { useState, useEffect } from 'react';
import { Button, Box, Typography } from '@mui/material';
import i18n from '../common/i18n';
import { getFeaturedNotes, getOtherNotes } from '../../api/serviceBlog';
import Banner from '../common/Banner';
import bannerImage from '../../assests/images/home/bannerHome.svg';
import blogBanner from '../../assests/images/blog/blogBanner.png';
import RowNotes from '../blog/RowNotes';
import '../../assests/css/blogStyle.css';
import HeaderTags from '../common/HeaderTags';

const Blog = () => {
  const [distinguishedNotes, setDisitinguishedNotes] = useState([{}]);
  const [generalNotes, setGeneralNotes] = useState([{}]);
  const [minimumId, setMinimumId] = useState(0);
  const [generalNotesToShow, setGeneralNotesToShow] = useState(0);

  const getDistinguishedNotes = async () => {
    const notes = await getFeaturedNotes();
    setDisitinguishedNotes(notes);
  };

  function minimumValue(notes) {
    return Math.min(...notes.map((o) => o.note_id));
  }

  const getMoreGeneralNotes = async () => {
    if (minimumId !== 1) {
      const notes = await getOtherNotes(minimumId);
      setGeneralNotes([...generalNotes, ...notes]);
      setGeneralNotesToShow(generalNotes.length + notes.length);
      setMinimumId(minimumValue(notes));
    }
  };

  const initGeneralNotes = async () => {
    if (minimumId !== 1) {
      const notes = await getOtherNotes(0);
      const notes2 = await getOtherNotes(minimumValue(notes));
      const notes3 = await getOtherNotes(minimumValue(notes2));
      setGeneralNotes([...notes, ...notes2, ...notes3]);
      setGeneralNotesToShow(24);
      setMinimumId(minimumValue(notes3));
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getDistinguishedNotes();
    initGeneralNotes();
  }, []);

  return (
    <>
      <HeaderTags title={i18n.metaTitleBlog} content={i18n.metaDescriptionBlog} scriptMarkUpSchema />
      <Box mt={10}>
        <Banner title={i18n.blogCredicuotas} backgroundImage={bannerImage} iconImage={blogBanner} />
      </Box>
      <Box className="centerTitle">
        <Typography variant="h3" fontWeight={800} component="h2" className="lettersGrey">
          {i18n.communityDistinguished}
        </Typography>
      </Box>
      <RowNotes notes={distinguishedNotes} />
      <Box className="centerTitle">
        <Typography variant="h3" fontWeight={800} component="h2" className="lettersGrey">
          {i18n.communityNotes}
        </Typography>
      </Box>
      {
        generalNotes.length > 1 && generalNotesToShow > 0 && (
          <RowNotes notes={generalNotes.slice(0, generalNotesToShow)} />
        )
      }
      {
        minimumId > 1 && (
          <Button variant="contained" onClick={getMoreGeneralNotes} className="ButtonMasNotas">
            {i18n.communityLoadMore}
          </Button>
        )
      }
      <Box className="centerTitle" ml={7} mr={7}>
        <Typography variant="p" className="disclaimerBlog">
          {i18n.legalDisclaimer}
        </Typography>
      </Box>
    </>
  );
};

export default Blog;
