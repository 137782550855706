import {
  Grid, Box, Button, Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import i18n from '../common/i18n';
import '../../assests/css/Seac.css';
import logoCredicuotas from '../../images/logoCredicuotas.png';
import logoCV from '../../assests/images/header/logo_cv.png';
import iconPhone from '../../assests/images/icons/phone_orange.svg';
import iconCheck from '../../assests/images/icons/check_mark_orange.svg';
import iconCash from '../../assests/images/icons/cash_orange.svg';
import {
  SEAC, getEnv,
} from '../../api/env';

const Recharge = () => {
  const SEAC_LINK = getEnv(SEAC);
  return (
    <>
      <Helmet>
        <title>{i18n.seacName}</title>
        <meta name="description" content={i18n.seacName} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <header style={{ backgroundColor: 'white' }}>
        <Grid container>
          <Grid item xs={10} md={2} className="gridLogoHeader">
            <img
              src={logoCV}
              alt={i18n.credicuotas}
              className="logoHeader"
            />
            <img
              src={logoCredicuotas}
              alt={i18n.credicuotas}
              className="logoHeader"
            />
          </Grid>
        </Grid>
      </header>
      {/* Banner */}
      <Grid container className="bannerSeac" mt={11}>
        <Grid item xs={1} />
        <Grid item xs={10} className="boxTitle">
          <Box>
            <Typography className="seacTitle">{i18n.seacTitle}</Typography>
            <Box className="seacSubtitle">
              <Typography variant="p">{i18n.seacSubtitle}</Typography>
            </Box>
            <a
              href={SEAC_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit', paddingTop: '2%' }}
            >
              <Button variant="contained" className="ButtonNaranja">
                {i18n.seacButton}
              </Button>
            </a>
          </Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {/* STEPS */}
      <Grid className="stepFondo">
        <Box className="boxStepSeac">
          <Typography variant="h4" fontWeight={600}>{i18n.seacStepTitle}</Typography>
          <Typography variant="p">{i18n.seacStepText}</Typography>
        </Box>
      </Grid>
      <Box className="boxContainerIcons">
        <Grid container className="stepFondo">
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxSeac">
              <img src={iconPhone} alt="telefono celular" className="seacIcons1" />
              <Typography variant="h6">{i18n.seacData}</Typography>
              <Typography variant="p">{i18n.seacDataText}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxSeac">
              <img src={iconCheck} alt="check" className="seacIcons2" />
              <Typography variant="h6">{i18n.seacValidation}</Typography>
              <Typography variant="p">{i18n.seacValidationText}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="gridIcons">
            <Box className="boxSeac">
              <img src={iconCash} alt="dinero" className="seacIcons3" />
              <Typography variant="h6">{i18n.seacAccreditation}</Typography>
              <Typography variant="p">{i18n.seacAccreditationText}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ backgroundColor: 'rgb(233, 136, 50)', color: 'white' }} className="seacTasasFooter">
        <Grid>
          <Typography variant="p" className="seacTasasText">{i18n.seacRates}</Typography>
          <Box className="spaceParagraph">
            <Typography variant="p" className="seacValores">{i18n.seacValues}</Typography>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default Recharge;
