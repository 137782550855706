import { useEffect } from 'react';
import {
  Box, Grid, Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18n from '../common/i18n';
import Banner from '../common/Banner';
import bannerImage from '../../assests/images/home/bannerHome.svg';
import iconBannerWc from '../../assests/images/wc/chicaMoto.png';
import '../../assests/css/wcStyle.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import shopWcIcon from '../../assests/images/wc/tienda-wc.png';
import vendorWcIcon from '../../assests/images/wc/comercio-wc.png';
import biciWcIcon from '../../assests/images/wc/bici-wc.png';
import affiliationCommerceWc from '../../assests/images/wc/adhesion.png';
import moto from '../../assests/images/home/moto.png';
import monitor from '../../assests/images/home/monitor.png';
import guitarra from '../../assests/images/wc/guitarra.png';
import mueble from '../../assests/images/wc/mueble.png';
import HeaderTags from '../common/HeaderTags';

const PhysicalCommerce = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <HeaderTags title={i18n.metaTitleWc} content={i18n.homePhysicalCommerce} />
      <Box mt={10}>
        <Banner
          title={i18n.wcTitle}
          subtitle={i18n.wcSubtitle}
          backgroundImage={bannerImage}
          iconImage={iconBannerWc}
        />
      </Box>
      <Box className="flexCenter">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthTitleHowWc marginTitleUs">
          {i18n.wcHow}
        </Typography>
      </Box>
      <Grid container className="spacePartnersCommerce">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12} lg={4} className="flexCenter">
              <Box className="boxCircleWc">
                <img src={shopWcIcon} alt="Tienda" className="imgShopWc" />
              </Box>
              <Box mt={2} className="widthBoxLess">
                <Typography variant="h6" fontWeight={700} className="lettersMagentaVersionThree">
                  {i18n.wcComeCommerce}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} className="flexCenter spaceCircleWc">
              <Box className="boxCircleWc">
                <img src={vendorWcIcon} alt="Vendedor" className="imgVendorWc" />
              </Box>
              <Box mt={2} className="widthBoxLess">
                <Typography variant="h6" fontWeight={700} className="lettersMagentaVersionThree">
                  {i18n.wcVendorData}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} className="flexCenter spaceCircleWc">
              <Box className="boxCircleWc">
                <img src={biciWcIcon} alt="Tienda" className="imgBiciWc" />
              </Box>
              <Box mt={2} className="widthBoxLessSmall">
                <Typography variant="h6" fontWeight={700} className="lettersMagentaVersionThree">
                  {i18n.wcProduct}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Box className="boxOwnerCommerce boxMainPartners">
        <Grid container>
          <Grid item xs={12} lg={6} className="flexCenter">
            <Box className="textLeft titleAFiliateCommerce">
              <Typography variant="h3" fontWeight={700} className="lettersGrey" pt={5}>
                {i18n.wcOwnerCommerce}
              </Typography>
              <Box pt={5}>
                <Typography variant="p" fontSize={20}>
                  {i18n.wcWriteUs}
                </Typography>
              </Box>
              <Box>
                <a
                  href="mailTo: comercios@credicuotas.com.ar"
                  rel="noopener noreferrer"
                  style={{ color: '#383838' }}
                >
                  <Typography variant="p" fontSize={20}>
                    {i18n.wcCommerceMail}
                  </Typography>
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} className="flexCenter">
            <img src={affiliationCommerceWc} alt="Adhesión de Comercios" className="imgAffiliationCommerce" />
          </Grid>
        </Grid>
      </Box>
      <Box className="spacePartnersCommerce">
        <Typography variant="h3" fontWeight={700} className="lettersMagenta">
          {i18n.wcAreas}
        </Typography>
      </Box>
      <Grid container className="spaceBetween">
        <Grid item xs={0.5} md={1} lg={1} />
        <Grid item xs={11} md={10} lg={10}>
          <Grid container>
            <Grid item xs={12} md={12} lg={5.75}>
              <Grid container className="flexCenter">
                <Grid
                  item
                  xs={5.5}
                  md={4}
                  lg={5.5}
                  className="boxProductsWc boxWcProducts"
                  pb={1}
                  backgroundColor="#FFC761"
                >
                  <img src={moto} alt="moto" className="imgMotoWc" />
                  <Box className="widthBoxTypography">
                    <Typography variant="h6" fontWeight={700} className="lettersGrey">
                      {i18n.wcMoto}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1} md={1} lg={1} />
                <Grid
                  item
                  xs={5.5}
                  md={4}
                  lg={5.5}
                  className="boxProductsWc boxWcProducts"
                  pb={2}
                  backgroundColor="#FFDFA5"
                >
                  <img src={monitor} alt="monitor" className="imgMonitorWc" />
                  <Box>
                    <Typography variant="h6" fontWeight={700} className="lettersGrey electroTypographyWc">
                      {i18n.wcElectro}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={0.5} />
            <Grid item xs={12} md={12} lg={5.75}>
              <Grid container className="flexCenter secondBoxesImages">
                <Grid
                  item
                  xs={5.5}
                  md={4}
                  lg={5.5}
                  className="boxProductsWc boxWcProducts"
                  pb={4}
                  backgroundColor="#EFBDDB"
                >
                  <img src={mueble} alt="Mueble" className="imgFurniture" />
                  <Box className="boxSelectorWidth">
                    <Typography variant="h6" fontWeight={700} className="lettersGrey">{i18n.wcFurniture}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={1} md={1} lg={1} />
                <Grid
                  item
                  xs={5.5}
                  md={4}
                  lg={5.5}
                  className="boxProductsWc boxWcProducts"
                  pb={3}
                  backgroundColor="#F9CFD4"
                >
                  <img src={guitarra} alt="Guitarra" className="imgGuitar" />
                  <Box className="boxSelectorWidth">
                    <Typography variant="h6" fontWeight={700} className="lettersGrey">{i18n.wcInstruments}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.5} md={1} lg={1} />
      </Grid>
      <Box className="flexCenter widthBoxTypography spacePartnersCommerce">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxConsumer marginTitleUs">
          {i18n.faq}
        </Typography>
      </Box>
      <Grid container className="spaceBetween" pb={5}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h3">{i18n.wcFirstQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="alignLeft">
              <Typography component="p" fontSize={18}>{i18n.wcFirstAnswer}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h3">{i18n.wcSecondQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="alignLeft">
              <Typography component="p" fontSize={18}>{i18n.wcSecondAnswer}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h3">{i18n.wcThirdQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="alignLeft">
              <Typography component="p" fontSize={18}>{i18n.wcThirdAnswer}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h3">{i18n.wcFourthQuestion}</Typography>
            </AccordionSummary>
            <AccordionDetails className="alignLeft">
              <Typography component="p" fontSize={18}>{i18n.wcFourthAnswer}</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

export default PhysicalCommerce;
