const markUpSchema = {
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  name: 'Credicuotas',
  url: 'https://www.credicuotas.com.ar/',
  logo: 'https://www.credicuotas.com.ar/static/media/logoCredicuotas.c8e5ff57023db5d2d581.png',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '0800 888 2733',
    contactType: 'customer service',
    availableLanguage: 'es',
  },
  sameAs: [
    'https://www.facebook.com/credicuotasarg',
    'https://www.linkedin.com/company/credicuotas/',
    'https://www.youtube.com/channel/UCIsQZSpW2M4DlGCSM6pBsWQ',
    'https://www.instagram.com/credicuotas.ar/',
    'https://www.credicuotas.com.ar/',
  ],
};

export default markUpSchema;
