import HistoryCard from './HistoryCard';
import i18n from './common/i18n';
import logoBind from '../assests/images/investors/grupo_bind.png';
import logoCredicuotas from '../assests/images/investors/credicuotas.png';
import logoPoincenot from '../assests/images/investors/poincenot.png';
import logoHonda from '../assests/images/investors/honda.png';
import logoUber from '../assests/images/investors/uber.png';
import logoMercadolibre from '../assests/images/investors/mercadolibre.png';
import logoCnv from '../assests/images/investors/cnv.png';
import logoShield from '../assests/images/investors/shield.svg';
import CustomSlider from './common/CustomSlider';

const HistorySlider = () => {
  const historyDataList = [
    {
      title: i18n.investorsHistory1title,
      description: i18n.investorsHistory1Description,
    },
    {
      title: i18n.investorsHistory2title,
      description: i18n.investorsHistory2Description,
    },
    {
      title: i18n.investorsHistory3title,
      description: i18n.investorsHistory3Description,
      img1: logoBind,
      img2: logoCredicuotas,
    },
    {
      title: i18n.investorsHistory4title,
      description: i18n.investorsHistory4Description,
    },
    {
      title: i18n.investorsHistory5title,
      description: i18n.investorsHistory5Description,
      img1: logoPoincenot,
    },
    {
      title: i18n.investorsHistory6title,
      description: i18n.investorsHistory6Description,
    },
    {
      title: i18n.investorsHistory7title,
      description: i18n.investorsHistory7Description,
    },
    {
      title: i18n.investorsHistory8title,
      description: i18n.investorsHistory8Description,
    },
    {
      title: i18n.investorsHistory9title,
      description: i18n.investorsHistory9Description,
      img1: logoHonda,
      img2: logoUber,
    },
    {
      title: i18n.investorsHistory10title,
      description: i18n.investorsHistory10Description,
      img1: logoMercadolibre,
    },
    {
      title: i18n.investorsHistory11title,
      description: i18n.investorsHistory11Description,
      img1: logoCnv,
    },
    {
      title: i18n.investorsHistory12title,
      description: i18n.investorsHistory12Description,
      img1: logoShield,
    },
  ];

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <CustomSlider customSettings={settings}>
      {historyDataList.map((historyData) => (
        <HistoryCard key={historyData.title} historyData={historyData} />
      ))}
    </CustomSlider>
  );
};

export default HistorySlider;
