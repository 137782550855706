import {
  Button, Card, CardActions, CardContent, CardMedia, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import i18n from '../common/i18n';
import '../../assests/css/generalStyle.css';
import { BLOG, getEnv } from '../../api/env';
import { route } from '../navigation/routes';

const urlBackend = getEnv(BLOG);

const NoteCard = ({
  noteId, title, image, description,
}) => {
  const urlImage = `${urlBackend}/${image}`;
  const keyWords = title
    .replace(/¿/g, '')
    .replace(/\?/g, '')
    .replace(/:/g, ' ')
    .replace(/\s+/g, '-')
    .toLowerCase();
  return (
    <Card sx={{ width: 355, height: 395 }}>
      <CardMedia
        component="img"
        height="140"
        image={urlImage}
        alt={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" fontWeight={600} component="div" className="lettersGrey">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions class="justify-center">
        <a href={`${route.blog}/${keyWords}/${noteId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Button
            variant="contained"
            className="ButtonBlog"
          >
            {i18n.noteCardViewMore}
          </Button>
        </a>
      </CardActions>
    </Card>
  );
};

NoteCard.propTypes = {
  noteId: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
};

NoteCard.defaultProps = {
  noteId: '',
  title: '',
  image: '',
  description: '',
};

export default NoteCard;
