import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import parse from 'html-react-parser';
import i18n from '../common/i18n';
import '../../assests/css/contractsStyle.css';
import {
  CONTRACT_SUC_TUC, CONTRACT_WC, CONTRACT_WT, CONTRACT_TARJETA_PREPAGA, getEnv,
} from '../../api/env';

const Contracts = () => {
  const openContract = (link) => {
    window.open(link, '_blanck');
  };

  return (
    <>
      <Box mt={9} mb={5} className="contracts-banner">
        <Typography className="contracts-banner-title">
          {parse(i18n.Contracts.bannerTitle)}
        </Typography>
      </Box>
      <Box mt={5} />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container className="contracts-box">
            <Grid item xs={12} md={9}>
              <Typography className="contracts-box-description">{i18n.Contracts.contract1}</Typography>
            </Grid>
            <Grid item xs={12} md={3} className="contracts-box-cta">
              <Button
                variant="contained"
                className="contracts-cta"
                onClick={() => openContract(getEnv(CONTRACT_WT))}
              >
                {i18n.Contracts.ctaLabel}
              </Button>
            </Grid>
          </Grid>
          <Box mt={2} />
          <Grid container className="contracts-box">
            <Grid item xs={12} md={9}>
              <Typography className="contracts-box-description">{i18n.Contracts.contract2}</Typography>
            </Grid>
            <Grid item xs={12} md={3} className="contracts-box-cta">
              <Button
                variant="contained"
                className="contracts-cta"
                onClick={() => openContract(getEnv(CONTRACT_SUC_TUC))}
              >
                {i18n.Contracts.ctaLabel}
              </Button>
            </Grid>
          </Grid>
          <Box mt={2} />
          <Grid container className="contracts-box">
            <Grid item xs={12} md={9}>
              <Typography className="contracts-box-description">{i18n.Contracts.contract3}</Typography>
            </Grid>
            <Grid item xs={12} md={3} className="contracts-box-cta">
              <Button
                variant="contained"
                className="contracts-cta"
                onClick={() => openContract(getEnv(CONTRACT_WC))}
              >
                {i18n.Contracts.ctaLabel}
              </Button>
            </Grid>
          </Grid>
          <Box mt={2} />
          <Grid container className="contracts-box">
            <Grid item xs={12} md={9}>
              <Typography className="contracts-box-description">{i18n.Contracts.contract4}</Typography>
            </Grid>
            <Grid item xs={12} md={3} className="contracts-box-cta">
              <Button
                variant="contained"
                className="contracts-cta"
                onClick={() => openContract(getEnv(CONTRACT_TARJETA_PREPAGA))}
              >
                {i18n.Contracts.ctaLabel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={10} />
    </>
  );
};

export default Contracts;
