import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import HeaderTags from '../common/HeaderTags';
import i18n from '../common/i18n';
import { isMobileOperatingSystem } from '../../utils/functionUtils';
import refurbishDesktopImg from '../../assests/images/refacciones/refacciones_desktop.png';
import refurbishMobileImg from '../../assests/images/refacciones/refacciones_mobile.png';
import LandingSteps from '../LandingSteps';
import LandingRequirements from '../LandingRequirements';
import LandingBrandAndModels from '../LandingBrandAndModels';
import LandingChooseUs from '../LandingChooseUs';
import LandingFaqs from '../LandingFaqs';

const LandingRefurbish = () => {
  const ctaBanner = () => {
    window.open('https://onboarding.credicuotas.com.ar/', '_blank');
  };

  return (
    <>
      <HeaderTags title={i18n.LandingRefurbish.title} content={i18n.LandingRefurbish.description} scriptMarkUpSchema />

      {/* BANNER */}
      <Box className="landing-banner">
        <Grid container display="flex" flexDirection="row" alignItems="center">
          {/* BANNER DESKTOP */}
          {!isMobileOperatingSystem() ? (
            <>
              <Grid item md={8}>
                <Box className="landing-banner-div" mt={4} mb={4}>
                  <Typography variant="h1">{i18n.LandingRefurbish.title}</Typography>
                  <Typography>{i18n.LandingRefurbish.description}</Typography>
                  <Button
                    onClick={ctaBanner}
                    variant="contained"
                    sx={{ marginTop: '20px' }}
                  >
                    {i18n.LandingRefurbish.ctaBanner}
                  </Button>
                </Box>
              </Grid>
              <Grid item md={4}>
                <img src={refurbishDesktopImg} alt="REFACCIONES" />
              </Grid>
            </>
          ) : (
            // BANNER MOBILE
            <Grid item md={8}>
              <Box className="landing-banner-div">
                <Typography variant="h1">{i18n.LandingRefurbish.title}</Typography>
                <Typography>{i18n.LandingRefurbish.description}</Typography>
                <Button
                  onClick={ctaBanner}
                  variant="contained"
                  sx={{ marginTop: '20px' }}
                >
                  {i18n.LandingRefurbish.ctaBanner}
                </Button>
                <img src={refurbishMobileImg} alt="REFACCIONES" style={{ maxWidth: '40%' }} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box className="body">
        {/* SECCION STEPS PARA COMPRAR ELECTRO */}
        <Box mt={5} />
        <LandingSteps title={i18n.LandingRefurbish.howToTitle} steps={i18n.LandingRefurbish.howToSteps} />

        {/* SECCION REQUISITOS */}
        <LandingRequirements
          title={i18n.LandingRefurbish.requirementsTitle}
          requirements={i18n.LandingRefurbish.requirements}
        />

        {/* SECCION MARCAS Y MODELOS */}
        <Box mt={5} />
        <LandingBrandAndModels
          title={i18n.LandingRefurbish.brandsTitle}
          description={i18n.LandingRefurbish.brandsDescription}
          brands="/images/refacciones/models_desktop.png"
          brandsMobile="/images/refacciones/models_mobile.png"
        />

        {/* SECCION ELEGINOS */}
        <LandingChooseUs
          titles={i18n.LandingRefurbish.chooseUsTitle}
          messages={i18n.LandingRefurbish.chooseUsMessages}
        />

        {/* SECCION FAQs */}
        <LandingFaqs faqs={i18n.LandingRefurbish.faqs} />
      </Box>
    </>
  );
};

export default LandingRefurbish;
