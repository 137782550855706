import {
  Grid, Box, Button, Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import i18n from '../common/i18n';
import bannerImage from '../../assests/images/home/bannerHome.svg';
import personImage from '../../assests/images/icons/iconoPersona.svg';
import unsubscribeImage from '../../assests/images/icons/unsubscribe.svg';
import payImage from '../../assests/images/icons/pay.svg';
import '../../assests/css/unsubscribeInformation.css';
import { AUTOGESTION, getEnv } from '../../api/env';
import Banner from '../common/Banner';

const UnsubscribeInformation = () => {
  const CLIENT_LINK = getEnv(AUTOGESTION);
  return (
    <>
      <Helmet>
        <title>{i18n.rechargeName}</title>
        <meta name="description" content={i18n.rechargeName} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {/* banner */}
      <Box mt={10}>
        <Banner title={i18n.unsuscribeInformationBannerTitle} backgroundImage={bannerImage} />
      </Box>
      <Box className="flexCenter">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxConsumer" mt={8}>
          {i18n.unsuscribeInformationTitle}
        </Typography>
      </Box>
      <Grid container mt={3} spacing={3}>
        <Grid item xs={12} md={4} lg={4} className="flexCenter">
          <div className="borderBox">
            <img src={personImage} alt="person" height={128} />
            <Typography variant="p" mt={2}>{i18n.unsuscribeInformationStep1}</Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={4} className="flexCenter">
          <div className="borderBox">
            <img src={unsubscribeImage} alt="unsubscribe" height={128} />
            <Typography variant="p" mt={2}>{i18n.unsuscribeInformationStep2}</Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={4} className="flexCenter">
          <div className="borderBox">
            <img src={payImage} alt="pay" height={128} />
            <Typography variant="p" mt={2}>{i18n.unsuscribeInformationStep3}</Typography>
          </div>
        </Grid>
      </Grid>
      <Box mb={10} mt={15}>
        <a href={CLIENT_LINK} rel="noopener noreferrer">
          <Button variant="contained" className="ButtonMagenta">
            <span className="marginText">{i18n.unsuscribeInformationBackButton}</span>
          </Button>
        </a>
      </Box>
    </>
  );
};

export default UnsubscribeInformation;
