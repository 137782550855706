const soapZendesk = (data) => {
  const body = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">'
   + '<soapenv:Header/>'
   + '<soapenv:Body>'
   + '<tem:btn_arrepentimiento>'
   + '<!--Optional:-->'
   + '<tem:Usuario>trenders</tem:Usuario>'
   + '<!--Optional:-->'
   + '<tem:Password>trenders_cc_2021</tem:Password>'
   + '<!--Optional:-->'
   + `<tem:campo_dni>${data.documentNumber}</tem:campo_dni>`
   + '<!--Optional:-->'
   + `<tem:campo_motivo_desc>${data.motive}</tem:campo_motivo_desc>`
   + '<!--Optional:-->'
   + '<tem:campo_canal>Boton de Arrepentimiento</tem:campo_canal>'
   + '<!--Optional:-->'
   + `<tem:campo_email>${data.mail}</tem:campo_email>`
   + '<!--Optional:-->'
   + `<tem:body_msj>${data.reason}</tem:body_msj>`
   + '</tem:btn_arrepentimiento>'
   + '</soapenv:Body>'
   + '</soapenv:Envelope>';

  return body;
};

export default soapZendesk;
