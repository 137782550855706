import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Dropdown = ({ subMenu, dropDown }) => {
  const [dropDownExpanded, setDropdownExpanded] = useState(false);
  return (
    <ul
      className={`dropdown ${((dropDown) || (dropDownExpanded)) ? 'show' : ''}`}
      onMouseEnter={() => setDropdownExpanded((prev) => !prev)}
      onMouseLeave={() => setDropdownExpanded((prev) => !prev)}
    >
      {subMenu.map((item) => (
        <li key={item.title} className="menu-items dropdownbtn">
          <Link to={item.route}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

Dropdown.propTypes = {
  subMenu: PropTypes.objectOf(Object).isRequired,
  dropDown: PropTypes.bool.isRequired,
};

export default Dropdown;
