import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import HeaderTags from '../common/HeaderTags';
import i18n from '../common/i18n';
import motoDesktopImg from '../../assests/images/motorcycle/scooter desktop.png';
import { isMobileOperatingSystem } from '../../utils/functionUtils';
import motoMobileImg from '../../assests/images/motorcycle/scooter mobile.png';
import LandingSteps from '../LandingSteps';
import LandingRequirements from '../LandingRequirements';
import LandingBrandAndModels from '../LandingBrandAndModels';
import LandingChooseUs from '../LandingChooseUs';
import LandingFaqs from '../LandingFaqs';

const Motorcycle = () => {
  const ctaBanner = () => {
    window.open('https://onboarding.credicuotas.com.ar/', '_blank');
  };

  return (
    <>
      <HeaderTags title={i18n.Motorcycle.title} content={i18n.Motorcycle.description} scriptMarkUpSchema />

      {/* BANNER */}
      <Box className="landing-banner">
        <Grid container display="flex" flexDirection="row" alignItems="center">
          {/* BANNER DESKTOP */}
          {!isMobileOperatingSystem() ? (
            <>
              <Grid item md={7}>
                <Box className="landing-banner-div">
                  <Typography variant="h1">{i18n.Motorcycle.title}</Typography>
                  <Typography>{i18n.Motorcycle.description}</Typography>
                  <Button
                    onClick={ctaBanner}
                    variant="contained"
                    sx={{ marginTop: '20px' }}
                  >
                    {i18n.Motorcycle.ctaBanner}
                  </Button>
                </Box>
              </Grid>
              <Grid item md={5}>
                <img src={motoDesktopImg} alt="SCOOTER" />
              </Grid>
            </>
          ) : (
            // BANNER MOBILE
            <Grid item md={7}>
              <Box className="landing-banner-div">
                <Typography variant="h1">{i18n.Motorcycle.title}</Typography>
                <Typography>{i18n.Motorcycle.description}</Typography>
                <Button
                  onClick={ctaBanner}
                  variant="contained"
                  sx={{ marginTop: '50px' }}
                >
                  {i18n.Motorcycle.ctaBanner}
                </Button>
                <img src={motoMobileImg} alt="SCOOTER" />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box className="body">
        {/* SECCION STEPS PARA COMPRAR MOTO */}
        <Box mt={5} />
        <LandingSteps title={i18n.Motorcycle.howToTitle} steps={i18n.Motorcycle.howToSteps} />

        {/* SECCION REQUISITOS */}
        <LandingRequirements title={i18n.Motorcycle.requirementsTitle} requirements={i18n.Motorcycle.requirements} />

        {/* SECCION MARCAS Y MODELOS */}
        <Box mt={5} />
        <LandingBrandAndModels
          title={i18n.Motorcycle.brandsTitle}
          description={i18n.Motorcycle.brandsDescription}
          brands="/images/motorcycle/models_desktop.png"
          brandsMobile="/images/motorcycle/models_mobile.png"
        />

        {/* SECCION ELEGINOS */}
        <LandingChooseUs
          titles={i18n.Motorcycle.chooseUsTitle}
          messages={i18n.Motorcycle.chooseUsMessages}
        />

        {/* SECCION FAQs */}
        <LandingFaqs faqs={i18n.Motorcycle.faqs} />
      </Box>
      <Box mb={5} className="landing-footer">
        <Typography>{i18n.Motorcycle.footer}</Typography>
      </Box>
    </>
  );
};

export default Motorcycle;
