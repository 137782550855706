import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { isMobileOperatingSystem } from '../utils/functionUtils';

const LandingSteps = ({ title, steps }) => (
  <>
    <Typography variant="h2">{title}</Typography>
    <Grid container className="landing-how-to-steps" spacing={2}>
      {steps.map((step) => (
        <Grid item md={4} xs={12} key={step.description}>
          <Box>
            <img src={isMobileOperatingSystem() ? step.image[1] : step.image[0]} alt="PASO" />
            <Typography className="landing-how-to-step">{step.description}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  </>
);

LandingSteps.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default LandingSteps;
