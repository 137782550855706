import { Grid, Box } from '@mui/material';
import PropTypes from 'prop-types';
import NoteCard from './NoteCard';

const RowNotes = ({ notes }) => {
  let quantityColumns = 3;
  if (notes.length === 2) {
    quantityColumns = 2;
  } else if (notes.length === 1) {
    quantityColumns = 1;
  }
  return (
    <Grid container>
      {notes.map((data) => (
        <Grid key={data.image} item xs={12} md={quantityColumns === 1 ? 12 : quantityColumns === 2 ? 6 : 4}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <NoteCard noteId={String(data.note_id)} title={data.title} image={data.image} description={data.text} />
          </Box>
          <Box sx={{ height: '70px' }} />
        </Grid>
      ))}
    </Grid>
  );
};

RowNotes.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default RowNotes;
