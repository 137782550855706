import {
  Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18n from '../common/i18n';

const helpCommerce = () => (
  <>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpCommerceFirstQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">
          {i18n.helpCommerceFirstAnswerOne}
          <a
            href="mailTo: comercios@credicuotas.com.ar"
            rel="noopener noreferrer"
            style={{ color: '#383838' }}
          >
            {i18n.helpCommerceFirstAnswerTwo}
          </a>
          {i18n.helpCommerceFirstAnswerThree}
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpCommerceSecondQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpCommerceSecondAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpCommerceThirdQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpCommerceThirdAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpCommerceFourthQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpCommerceFourthAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpCommerceFifthQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpCommerceFifthAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
  </>
);

export default helpCommerce;
