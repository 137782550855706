import {
  Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18n from '../common/i18n';
import {
  AUTOGESTION, ORIGINACION_WT, getEnv,
} from '../../api/env';

const ORIGINACION_WT_LINK = getEnv(ORIGINACION_WT);
const AUTOGESTION_LINK = getEnv(AUTOGESTION);

const helpWc = () => (
  <>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpWcFirstQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpWcFirstAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpWcSecondQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpWcSecondAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpWcThirdQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpWcThirdAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpWcFourthQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpWcFourthAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpWcFifthQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpWcFifthAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpWcSixthQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpWcSixthAnswerOne}</Typography>
        <br />
        <Typography component="p" className="answers">{i18n.helpWcSixthAnswerTwo}</Typography>
        <br />
        <ul style={{ marginLeft: '30px', textAlign: 'left' }}>
          <li>
            <Typography>
              {i18n.helpWcSixthAnswerThree}
              <a
                href={AUTOGESTION_LINK}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit' }}
              >
                {i18n.helpWcSixthAnswerFour}
              </a>
              {i18n.helpWcSixthAnswerFive}
            </Typography>
          </li>
          <li>
            <Typography>{i18n.helpWcSixthAnswerSix}</Typography>
          </li>
        </ul>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpWcSeventhQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">{i18n.helpWcSeventhAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="spaceAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="questions">{i18n.helpWcEighthQuestion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className="answers">
          {i18n.helpWcEighthAnswerOne}
          <a
            href={ORIGINACION_WT_LINK}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'inherit' }}
          >
            {i18n.helpWcEighthAnswerTwo}
          </a>
        </Typography>
      </AccordionDetails>
    </Accordion>
  </>
);

export default helpWc;
