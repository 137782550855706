import {
  Button,
} from '@mui/material';
import i18n from '../common/i18n';
import '../../assests/css/headerStyle.css';
import MenuItems from './MenuItems';
import { AUTOGESTION, COMMERCE, getEnv } from '../../api/env';

const NavBar = () => {
  const COMMERCE_LINK = getEnv(COMMERCE);
  const CLIENT_LINK = getEnv(AUTOGESTION);

  const headerMenuWhereToBuy = {
    title: i18n.headerWhereToBuyTitle,
    subMenu: [i18n.headerMenuPhysicalCommerce],
  };

  const menuItems = [
    i18n.headerMenuHome,
    headerMenuWhereToBuy,
    i18n.headerMenuCard,
    i18n.headerMenuBlog,
    i18n.headerMenuHelp,
  ];

  return (
    <nav>
      <ul className="menus">
        {menuItems.map((item) => (
          <MenuItems items={item} key={item.title} />
        ))}
        <li>
          <a
            href={COMMERCE_LINK}
            target="_blank"
            rel="noopener noreferrer nofollow"
            style={{ textDecoration: 'none' }}
          >
            <Button sx={{ marginLeft: '10px' }} variant="contained" className="ButtonMagenta ButtonYellow">
              {i18n.headerCommerce}
            </Button>
          </a>
          <a
            href={CLIENT_LINK}
            target="_blank"
            rel="noopener noreferrer nofollow"
            style={{ textDecoration: 'none' }}
          >
            <Button sx={{ marginLeft: '10px' }} variant="contained" className="ButtonMagenta">
              {i18n.headerClient}
            </Button>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
