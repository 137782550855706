import {
  Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18n from '../common/i18n';
import {
  AUTOGESTION, CONTRACT_WT, ORIGINACION_WT, getEnv,
} from '../../api/env';

const helpWt = () => {
  const ORIGINACION_WT_LINK = getEnv(ORIGINACION_WT);
  const AUTOGESTION_LINK = getEnv(AUTOGESTION);
  const CONTRACT_LINK = getEnv(CONTRACT_WT);
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className="questions">{i18n.helpWtFirstQuestion}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="p" className="answers">
            {i18n.helpWtFirstAnswerOne}
            <a
              href={ORIGINACION_WT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'inherit' }}
            >
              {i18n.helpOurWeb}
            </a>
            {i18n.helpWtFirstAnswerTwo}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="spaceAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className="questions">{i18n.helpWtSecondQuestion}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="p" className="answers">
            {i18n.helpWtSecondAnswer}
            <a
              href={ORIGINACION_WT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'inherit' }}
            >
              {i18n.helpOurWeb}
            </a>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="spaceAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className="questions">{i18n.helpWtThirdQuestion}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="p" className="answers">{i18n.helpWtThirdAnswer}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="spaceAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className="questions">{i18n.helpWtFourthQuestion}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="p" className="answers">
            {i18n.helpWtFourthAnswerOne}
            <a
              href={ORIGINACION_WT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'inherit' }}
            >
              {i18n.helpOurWeb}
            </a>
            {i18n.helpWtFourthAnswerTwo}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="spaceAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className="questions">{i18n.helpWtFifthQuestion}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="p" className="answers">
            {i18n.helpWtFifthAnswerOne}
            <a
              href={ORIGINACION_WT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'inherit' }}
            >
              {i18n.helpOurWeb}
            </a>
            {i18n.helpWtFifthAnswerTwo}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="spaceAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className="questions">{i18n.helpWtSixthQuestion}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="p" className="answers">
            {i18n.helpWtSixthAnswerOne}
            <a
              href={ORIGINACION_WT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'inherit' }}
            >
              {i18n.helpOurWeb}
            </a>
            {i18n.helpWtSixthAnswerTwo}
          </Typography>
          <Typography component="p" className="answers">{i18n.helpWtSixthAnswerThree}</Typography>
          <Typography component="p" className="answers">{i18n.helpWtSixthAnswerFour}</Typography>
          <Typography component="p" className="answers">{i18n.helpWtSixthAnswerFive}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="spaceAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className="questions">{i18n.helpWtSeventhQuestion}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="p" className="answers">
            {i18n.helpWtSeventhAnswer}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="spaceAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className="questions">{i18n.helpWtEighthQuestion}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="p" className="answers">{i18n.helpWtEighthAnswerOne}</Typography>
          <Typography component="p" className="answers">
            {i18n.helpWtEighthAnswerTwo}
            <strong>{i18n.helpWtEighthAnswerThree}</strong>
          </Typography>
          <Typography component="p" className="answers">
            {i18n.helpWtEighthAnswerFour}
            <a
              href={AUTOGESTION_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'inherit' }}
            >
              {i18n.helpWtEighthAnswerFive}
            </a>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="spaceAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className="questions">{i18n.helpWtNinethQuestion}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="p" className="answers">{i18n.helpWtNinethAnswerOne}</Typography>
          <Typography component="p" className="answers">{i18n.helpWtNinethAnswerTwo}</Typography>
          <Typography component="p" className="answers">{i18n.helpWtNinethAnswerThree}</Typography>
          <Typography component="p" className="answers">{i18n.helpWtNinethAnswerFourth}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="spaceAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className="questions">{i18n.helpWtTenthQuestion}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="p" className="answers">
            {i18n.helpWtTenthAnswerOne}
            <a
              href={ORIGINACION_WT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'inherit' }}
            >
              {i18n.helpOurWeb}
            </a>
            {i18n.helpWtTenthAnswerTwo}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="spaceAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className="questions">{i18n.helpWtEleventhQuestion}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="p" className="answers">
            {i18n.helpWtEleventhAnswerOne}
          </Typography>
          <Typography component="p" className="answers">
            <a
              href={CONTRACT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {i18n.helpWtEleventhAnswerTwo}
            </a>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default helpWt;
