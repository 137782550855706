import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { isMobileOperatingSystem } from '../utils/functionUtils';

const LandingChooseUs = ({ titles, messages }) => (
  <Box className="landing-choose-us">
    <Typography variant="h2">
      {isMobileOperatingSystem() ? titles[1] : titles[0]}
    </Typography>
    <Box>
      {messages.map((message) => (
        <Box key={message.title} className="landing-choose-us-message">
          <img
            src={isMobileOperatingSystem()
              ? '/images/check_mobile.png' : '/images/check.png'}
            alt="check"
          />
          <Typography variant="h4">
            {message.title}
            <Typography>{message.description}</Typography>
          </Typography>
        </Box>
      ))}
    </Box>
  </Box>
);

LandingChooseUs.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default LandingChooseUs;
