import PropTypes from 'prop-types';
import {
  Box, Button, Typography, useMediaQuery,
} from '@mui/material';
import '../assests/css/onboardingCardStyle.css';
import { ArrowForward } from '@mui/icons-material';

const OnboardingCard = ({
  title, description, ctaLabel, imgDesktop, imgMobile, onClick,
}) => {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  return (
    <Box>
      {isMobile ? (
        <Box className="onboarding-card-container" onClick={onClick}>
          <img src={imgMobile} alt="LOGO" />
          <Box ml={1} width="100%">
            <Typography className="onboarding-card-title">
              {title}
              <ArrowForward />
            </Typography>
            <Typography className="onboarding-card-description">{description}</Typography>
          </Box>
        </Box>
      ) : (
        <Box className="onboarding-card-container">
          <Box className="onboarding-card-info">
            <Typography className="onboarding-card-title">{title}</Typography>
            <Typography className="onboarding-card-description">{description}</Typography>
            <Box mt={4} />
            <Button variant="contained" className="onboarding-card-cta" onClick={onClick}>{ctaLabel}</Button>
          </Box>
          <img src={imgDesktop} alt="LOGO" className="onboarding-card-img" />
        </Box>
      )}
    </Box>
  );
};

OnboardingCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  imgDesktop: PropTypes.string.isRequired,
  imgMobile: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OnboardingCard;
