import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { getFooterData } from '../../api/services';
import '../../assests/css/legalFooter.css';

const LegalFooter = ({ boxStyle, footerName }) => {
  const [footer, setFooter] = useState();
  async function getFooter() {
    try {
      const { data } = await getFooterData(footerName);
      setFooter(data);
    } catch (error) {
      console.error('There was an error!', error);
    }
  }

  useEffect(() => { getFooter(); }, []);
  return (
    <Box style={boxStyle} className="legalFooterTasasFooter">
      <Grid>
        <Typography variant="p" className="legalFooterTasasText">{footer ? footer.ratesAndExample : ''}</Typography>
        <Box className="spaceBox">
          <Typography variant="p" className="legalFooterTasasText">{footer ? footer.ratesAndExample2 : ''}</Typography>
        </Box>
        <Box className="spaceBox">
          <Typography variant="p" className="legalFooterValorCFTEA">
            {footer ? footer.totalFinantialCost : ''}
          </Typography>
        </Box>
        <Typography variant="p" className="legalFooterValores">
          {footer ? footer.taxesPhrase : ''}
        </Typography>
        <Box>
          <Typography variant="p" className="legalFooterValores">
            {footer ? footer.finantialCostWithoutTaxes : ''}
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

LegalFooter.propTypes = {
  boxStyle: PropTypes.objectOf(Object),
  footerName: PropTypes.string,
};

LegalFooter.defaultProps = {
  boxStyle: { backgroundColor: 'black', color: 'white', marginTop: '2%' },
  footerName: 'default',
};

export default LegalFooter;
